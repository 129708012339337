import React, { Component } from 'react';
import { Field, change } from 'redux-form';
import { FormTemplateField } from '../../../../others'
import { Input, Textarea, InputWithIcon, AutoCompleteSelect } from '../../../../form/'
import CanvasDrawing from '../../../../others/CanvasDrawing';
import ContactLens from '../../../../../assets/static/images/cl-draw.png'
import SetDistanceNear from '../SetDistanceNear';
import { connect } from 'react-redux';
import { contactLensTypeFields } from '../../../../Constants';
import { handleContactLensFieldsCopy, humanize, isPresentInArray } from '../../../../HelperFunctions';
class LensForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOdLensTypeLoading: false,
      isOsLensTypeLoading: false,
      osReset: false,
      odReset: false,
      odLensTypeFields: [],
      osLensTypeFields: [],
    }
  }

  setLensDistanceNear(visual, value) {
    const { name } = this.props
    this.props.dispatch(change('examRefractionContactLensForm', `${name}[visual_acuity][od_${visual}]`, value))
    this.props.dispatch(change('examRefractionContactLensForm', `${name}[visual_acuity][os_${visual}]`, value))
    this.props.dispatch(change('examRefractionContactLensForm', `${name}[visual_acuity][ou_${visual}]`, value))
  }

  copyTo(source, destination) {
    const { name, pprops, currentContactLens } = this.props
    const currentContactLensId = this.refs[`${name}[${source}_contact_lens_id]`].value
    const currentContactLen = currentContactLens[`${source}_contact_lens_attributes`]['lens_type']
    const overRefractionNode = this.refs[`${name}[${source}_over_refraction]`].value

    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_contact_lens_attributes]`, currentContactLens[source + '_contact_lens_attributes']))
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_contact_lens_id]`, currentContactLensId))
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_over_refraction]`, overRefractionNode))

    handleContactLensFieldsCopy(this, pprops, currentContactLen, name, destination, source, 'examRefractionContactLensForm')
    setTimeout(() => pprops.submit(), 500)
    setTimeout(() => this.handleContactLensFieldUpdate(destination), 1500);
  }

  onContactLensSelect(type) {
    const { isTemplate, pprops } = this.props
    this.setState({ [`is${humanize(type)}LensTypeLoading`]: true, [`${type}Reset`]: true })
    !isTemplate && setTimeout(() => pprops.submit(), 500)
    setTimeout(() => this.handleContactLensFieldUpdate(type), 1500);
  }

  handleContactLensFieldUpdate(type) {
    const { currentContactLens: { os_contact_lens_attributes, od_contact_lens_attributes } } = this.props
    const lensType = type === 'od' ? od_contact_lens_attributes.lens_type : os_contact_lens_attributes.lens_type
    this.setState({ [`${type}LensTypeFields`]: contactLensTypeFields[lensType] })
  }

  renderOdLabel() {
    return (
      <span>OD<small className="float-right btn-link" onClick={() => !this.props.isTemplate && this.copyTo('od', 'os')}><i className="la la-sm la-copy" />OS</small></span>
    )
  }

  renderOsLabel() {
    return (
      <span>OS<small className="float-right btn-link" onClick={() => !this.props.isTemplate && this.copyTo('os', 'od')}><i className="la la-sm la-copy" />OD</small></span>
    )
  }
  buildLensTypeData(arr) {
    var data = []
    arr && arr.map((val) => {
      data = [...data, { key: val, value: val }]
    })
    return data
  }

  handleAddSearchValSubmit(val, contact_lens_id, field_name, type) {
    const { name, pprops } = this.props
    const addToSettings = { type: type, val: val, field_name: field_name, setting_contact_lens_id: contact_lens_id }
    this.setState({ [`is${humanize(type)}LensTypeLoading`]: true, [`${type}Reset`]: true })
    this.props.dispatch(change('examRefractionContactLensForm', `${name}[add_lens_values]`, addToSettings))
    setTimeout(() => pprops.submit(), 500)
  }

  componentDidMount() {
    const { currentContactLens: { os_contact_lens_attributes, od_contact_lens_attributes }, currentContactLens } = this.props
    this.setState({
      odReset: false, osReset: false,
      odLensTypeFields: od_contact_lens_attributes ? contactLensTypeFields[od_contact_lens_attributes?.lens_type] : contactLensTypeFields[currentContactLens?.od_lens_type],
      osLensTypeFields: os_contact_lens_attributes ? contactLensTypeFields[os_contact_lens_attributes?.lens_type] : contactLensTypeFields[currentContactLens?.os_lens_type],
    })
  }

  getStoreIdForSearch() {
    const { orderStoreId } = this.props
    let StoreId = localStorage.getItem('StoreID')
    if (!StoreId && StoreId == null) {
      return orderStoreId
    }
    return StoreId;
  }

  render() {
    const { name, baseName, fieldValues, isTemplate, pprops, currentContactLens: { od_draw, os_draw, od_contact_lens_attributes, os_contact_lens_attributes, is_marked_complete } } = this.props
    return (
      <span>
        <div className="row mt-5">
          <div className='col-2 form-group'>
            <Field
              key={od_contact_lens_attributes?.id}
              ref={`${name}[od_contact_lens_id]`}
              name={`${name}[od_contact_lens_id]`}
              displayName={`${name}[od_contact_lens_brand_name]`}
              component={AutoCompleteSelect}
              onChangeHook={() => this.onContactLensSelect('od')}
              klass="Settings::ContactLens"
              additionalFilters={{ store_inventory: this.getStoreIdForSearch() }}
              textField="brand_name"
              valueField="id"
              placeholder="Placeholder"
              className="form-control"
              label={this.renderOdLabel()}
              labelClasses="w-100"
            />
            <small>{od_contact_lens_attributes?.manufacturer_name}</small>
        </div>
            
          {this.state.isOdLensTypeLoading ?
            this.loadingSpinner("isOdLensTypeLoading") :
            <div className="col-6 px-0">
              <div className="row mx-0">
                {this.state.odLensTypeFields?.map((fieldName) => {
                  return <>
                    {isPresentInArray(this.state.odLensTypeFields, `${fieldName}`) && this.renderLensFields('od', fieldName, name, od_contact_lens_attributes)}
                  </>
                })}
              </div>
            </div>
          }
          {/* <Field ref={`${name}[od_notes]`} name={`${name}[od_notes]`} label="Notes" rows={1} component={Textarea} className="form-control" wrapperClasses="col-1 form-group" /> */}
          <FormTemplateField
            isTemplate={isTemplate}
            ref={`${name}[od_notes]`}
            name={`${name}[od_notes]`}
            baseName={`${baseName}[fields][od_notes]`}
            fieldValues={fieldValues['fields']['od_notes']}
            component={Textarea}
            rows={1}
            className="form-control"
            wrapperClasses={`col-1 form-group`}
          />
          <FormTemplateField
            isTemplate={isTemplate}
            ref={`${name}[od_over_refraction]`}
            name={`${name}[od_over_refraction]`}
            baseName={`${baseName}[fields][od_over_refraction]`}
            fieldValues={fieldValues['fields']['od_over_refraction']}
            component={Input}
            className="form-control"
            wrapperClasses={`col-1 form-group`}
          />
          {/* <Field ref={`${name}[od_over_refraction]`} name={`${name}[od_over_refraction]`} label="Over Refraction" component={Input} className="form-control" wrapperClasses="col-1 form-group" /> */}
          <Field ref={`${name}[visual_acuity][od_distance]`} name={`${name}[visual_acuity][od_distance]`} label="OD Distance" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col-1 px-3" labelClasses="w-100" />
          <Field ref={`${name}[visual_acuity][od_near]`} name={`${name}[visual_acuity][od_near]`} label="OD Near" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col-1 px-3" labelClasses="w-100" />
        </div>
        <div className="row">
          <div className='col-2 form-group'>
            <Field
              key={os_contact_lens_attributes?.id}
              ref={`${name}[os_contact_lens_id]`}
              name={`${name}[os_contact_lens_id]`}
              displayName={`${name}[os_contact_lens_brand_name]`}
              component={AutoCompleteSelect}
              onChangeHook={() => this.onContactLensSelect('os')}
              klass="Settings::ContactLens"
              additionalFilters={{ store_inventory: this.getStoreIdForSearch() }}
              textField="brand_name"
              valueField="id"
              placeholder="Placeholder"
              className="form-control"
              label={this.renderOsLabel()}
              labelClasses="w-100"
            />
            <small>{os_contact_lens_attributes?.manufacturer_name}</small>
          </div>

          {this.state.isOsLensTypeLoading ?
            this.loadingSpinner("isOsLensTypeLoading") :
            <div className="col-6 px-0">
              <div className="row mx-0">
                {this.state.osLensTypeFields?.map((fieldName) => {
                  return <>
                    {isPresentInArray(this.state.osLensTypeFields, `${fieldName}`) && this.renderLensFields('os', fieldName, name, os_contact_lens_attributes)}
                  </>
                })}
              </div>
            </div>
          }

          <FormTemplateField
            isTemplate={isTemplate}
            ref={`${name}[os_notes]`}
            name={`${name}[os_notes]`}
            baseName={`${baseName}[fields][os_notes]`}
            fieldValues={fieldValues['fields']['os_notes']}
            component={Textarea}
            rows={1}
            className="form-control"
            wrapperClasses={`col-1 form-group`}
          />
          <FormTemplateField
            isTemplate={isTemplate}
            ref={`${name}[os_over_refraction]`}
            name={`${name}[os_over_refraction]`}
            baseName={`${baseName}[fields][os_over_refraction]`}
            fieldValues={fieldValues['fields']['os_over_refraction']}
            component={Input}
            className="form-control"
            wrapperClasses={`col-1 form-group`}
          />
          {/* <Field ref={`${name}[os_over_refraction]`} name={`${name}[os_over_refraction]`} label="Over Refraction" component={Input} className="form-control" wrapperClasses="col-1 form-group" /> */}
          <Field ref={`${name}[visual_acuity][os_distance]`} name={`${name}[visual_acuity][os_distance]`} label="OS Distance" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col-1 px-3" labelClasses="w-100" />
          <Field ref={`${name}[visual_acuity][os_near]`} name={`${name}[visual_acuity][os_near]`} label="OS Near" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col-1 px-3" labelClasses="w-100" />
        </div>
        <div className="row">
          <div className="col-3">
            <FormTemplateField
              isTemplate={isTemplate}
              name={`${name}[notes]`}
              baseName={`${baseName}[fields][notes]`}
              fieldValues={fieldValues['fields']['notes']}
              component={Textarea}
              rows={4}
              className="form-control"
              wrapperClasses="form-group label-icons"
            />
            <FormTemplateField
              isTemplate={isTemplate}
              name={`${name}[intraoffice_notes]`}
              baseName={`${baseName}[fields][intraoffice_notes]`}
              fieldValues={fieldValues['fields']['intraoffice_notes']}
              component={Textarea}
              rows={2}
              className="form-control"
              wrapperClasses="form-group label-icons"
            />
          </div>
          <div className="col-3">
            <label>OD Draw</label>
            <CanvasDrawing
              isTemplate={isTemplate}
              wrapperClasses="bg-light rounded text-center pt-3 pb-5"
              className="mx-auto"
              canvasRef={name + 'od_draw'}
              canvasWidth={343}
              canvasHeight={124}
              imgSrc={ContactLens}
              isMarkCompleted={isTemplate ? true : is_marked_complete}
              defaultData={od_draw}
              onChangeHook={(val) => pprops.dispatch(change('examRefractionContactLensForm', `${name}[od_draw]`, val))}
            />
          </div>
          <div className="col-3">
            <label>OS Draw</label>
            <CanvasDrawing
              isTemplate={isTemplate}
              wrapperClasses="bg-light rounded text-center pt-3 pb-5"
              className="mx-auto"
              canvasRef={name + 'os_draw'}
              canvasWidth={343}
              canvasHeight={124}
              imgSrc={ContactLens}
              isMarkCompleted={isTemplate ? true : is_marked_complete}
              defaultData={os_draw}
              onChangeHook={(val) => pprops.dispatch(change('examRefractionContactLensForm', `${name}[os_draw]`, val))}
            />
          </div>

          <div className="col-1"></div>

          <div className="col-2">
            <div className="row">
              <Field ref={`${name}[visual_acuity][ou_distance]`} name={`${name}[visual_acuity][ou_distance]`} label="OU Distance" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col form-group px-3" labelClasses="w-100" />
              <Field ref={`${name}[visual_acuity][ou_near]`} name={`${name}[visual_acuity][ou_near]`} label="OU Near" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col form-group px-3" labelClasses="w-100" />
            </div>
            <div className="row">
              {
                !isTemplate &&
                <>
                  <SetDistanceNear setVisualAcuity={this.setLensDistanceNear.bind(this)} visual='distance' />
                  <SetDistanceNear setVisualAcuity={this.setLensDistanceNear.bind(this)} visual='near' isDropLeft={true} />
                </>
              }
            </div>
          </div>
        </div>
      </span>
    );
  }

  renderLensFields(lens_type, fieldName, name, contact_lens_attributes) {
    const contactLensFields = { base_curves: 'curve', diameters: 'diameter', spheres: 'sphere' };
    const contactLensExamField = contactLensFields[fieldName] || fieldName;

    const isShowOnlyExistValPrefill = (fieldName === 'base_curves' || fieldName === 'diameters') ? true : false
    return (
      <>
        <Field
          ref={`${name}[${lens_type}_${contactLensExamField}]`}
          name={`${name}[${lens_type}_${contactLensExamField}]`}
          label={`${humanize(contactLensExamField)}`}
          component={AutoCompleteSelect}
          reset={lens_type === 'od' ? this.state.odReset : this.state.osReset}
          data={this.buildLensTypeData(contact_lens_attributes && contact_lens_attributes[fieldName])}
          textField="value"
          valueField="key"
          wrapperClasses="col-2 mb-4"
          key={`${name}[${lens_type}_${contactLensExamField}]-${contact_lens_attributes?.id}`}
          displayName={`${name}[${lens_type}_${contactLensExamField}]`}
          hideResetButton={true}
          className="form-control"
          labelClasses="w-100"
          showOnlyExistValPrefill={isShowOnlyExistValPrefill}
          showAddOption={true}
          addSearchValSubmit={(val) => this.handleAddSearchValSubmit(val, contact_lens_attributes?.id, contactLensExamField, lens_type)}
        />
      </>
    )
  }

  loadingSpinner(fieldType) {
    return (
      <div className="col-6 d-flex align-items-center">
        <span className="spinner-border text-primary" style={{ width: '1.25rem', height: '1.25rem' }} role="status" />
        <h6 className="d-inline-block pl-3">Loading Lens Type...</h6>
        <span className="d-none">{setTimeout(() => { this.setState({ [fieldType]: false }) }, 3000)}</span>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { order: { order } } = state
  return {
    orderStoreId: order.store_id
  }
}

export default connect(mapStateToProps)(LensForm);
import React, { useEffect } from 'react'
import Calling from './voip/Calling'
import VoipStatusNotification from './voip/VoipStatusNotification'
import ItrustPayNotification from './itrustPay/ItrustPayNotification'
import { connect, useDispatch } from 'react-redux'
import WhatsAppPopupWrap from './whatsApp/patient/WhatsAppPopupWrap'
import { callPopUpEdit } from '../../../redux/actions/addOns/voip/voipActions'
import { whatsAppEdit, whatsAppProfileLoad } from '../../../redux/actions/addOns/whatsApp/whatsAppActions'
import WhatsAppNotification from './whatsApp/WhatsAppNotification'
import VerifiedInfoView from './rpa/eligibilityVerify/VerifiedInfoView'
import { rpaServicesLoad } from '../../../redux/actions/addOns/rpa/rpaAction'

function AddOns(props) {
  const { itrustPayConfig, voipConfig, whatsAppConfig, userType, whatsAppPatientProfile, rpaDetailViewPopup, rpaConfig } = props
  const dispatch = useDispatch()

  useEffect(() => {
    (rpaConfig?.status && rpaConfig?.status !== 'inactive') && handleRpaServicesLoad()
  }, [rpaConfig])

  const handleRpaServicesLoad = async () => {
    let storeID = localStorage.getItem('StoreID')
    if (storeID) {
      dispatch(rpaServicesLoad({ store_id: storeID, category: "rpa" }))
    }
  }

  useEffect(() => {
    let storeID = localStorage.getItem('StoreID')
    whatsAppConfig?.status && whatsAppConfig?.status !== 'inactive' && storeID && dispatch(whatsAppProfileLoad({ store_id: storeID, category: "whatsapp", include: '*' }))
  }, [whatsAppConfig])

  useEffect(() => {
    if (!userType) {
      // On Logout Reset PopUps
      dispatch(callPopUpEdit(''))
      dispatch(whatsAppEdit({ patientProfile: false }))
    }
  }, [userType])

  return (
    <>
      <Calling />
      {userType !== 'Patient' && <>
        {(voipConfig?.visible && voipConfig?.status !== 'inactive') && <>
          <audio loop autoPlay id='bandwidth-ringtone' className='d-none'></audio>
          <VoipStatusNotification />
        </>}
        {(itrustPayConfig?.visible && itrustPayConfig?.status !== 'inactive') && <ItrustPayNotification />}
        {(whatsAppConfig?.visible && whatsAppConfig?.status !== 'inactive') && <WhatsAppNotification />}
      </>}
      {whatsAppPatientProfile && <WhatsAppPopupWrap />}
      {rpaConfig?.visible && rpaDetailViewPopup.visible && <VerifiedInfoView rpaDetailViewPopup={rpaDetailViewPopup} />}
    </>
  )
}

const mapStateToProps = (state) => {
  const { organisation: { organisation }, auth: { user }, whatsApp, rpa } = state
  return {
    itrustPayConfig: organisation.add_ons_config?.type?.itrust_pay,
    whatsAppConfig: organisation.add_ons_config?.type?.whatsapp,
    voipConfig: organisation.add_ons_config?.type?.voip,
    userType: user?.type,
    whatsAppPatientProfile: whatsApp.patientProfile,
    rpaDetailViewPopup: rpa.detailViewPopup,
    rpaConfig: organisation.add_ons_config?.type?.rpa
  }
}

export default connect(mapStateToProps)(AddOns)
import React, { useEffect } from 'react';
import ActionCable from 'actioncable';
import { WS_ROOT } from '../../../../env';
import { connect, useDispatch } from 'react-redux';
import { devicePaymentStatusUpdate } from '../../../../redux/actions/order/orderPaymentActions';
import { orderLoad } from '../../../../redux/actions/order/orderActions';
import * as $ from 'jquery';

function ItrustPayNotification(props) {
  const { currentUser } = props

  const dispatch = useDispatch()

  useEffect(() => {
    let accessToken = localStorage.getItem('Access-Token');
    (accessToken && accessToken !== 'null' && accessToken.length > 15) && handleActionCableCreate()
  }, [])


  const handleActionCableCreate = () => {
    setTimeout(function () {
      var cable = ActionCable.createConsumer(getWebSocketURL());
      cable.subscriptions.create(
        {
          channel: 'ItrustPayChannel',
        },
        {
          connected: function () {
            console.log('iTRUST Pay Socket Connected');
          },
          disconnected: function () {
            console.log('iTRUST Pay Socket Disconnected');
          },
          received: function (data) {
            data = JSON.parse(data.data)
            if(data.status){
              localStorage.removeItem('deviceLogId')
              dispatch(devicePaymentStatusUpdate({active: true, status: data.status, amount: data.amount, error_message: data.error_message }));
              dispatch(orderLoad(data.order_id, { include: '*' }))
              if(data.status==='completed'){
                setTimeout(() => {
                  dispatch(devicePaymentStatusUpdate({active: false, status: '' }));
                  $("#orderFinalizeForm-modal").modal('hide')
                }, 3000);
              }
              else if (data.status === 'failed') {
                localStorage.removeItem('deviceLogId')
              }
            }
            console.log('notification received');
          },
          speak: function (message) {
          }
        }
      );
    }, 4000)
  }

  const getWebSocketURL = () => {
    const token = localStorage.getItem('Access-Token')
    return `${WS_ROOT}/cable?token=${token}&org=org&ch=pay`
  }

  return (
    <></>
  )
}

const mapStateToProps = (state) => {
  const { auth } = state
  return {
    currentUser: auth.user,
  }
}

export default connect(mapStateToProps)(ItrustPayNotification)
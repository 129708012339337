import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DatePicker } from 'react-widgets'
import { medicalRxsLoad, medicalRxUpdate, medicalRxDelete, medicalRxSecureFax } from '../../../../redux/actions/medicalRxActions'
import {patientReportPreview} from '../../../../redux/actions/patient/patientReportActions'

import MedicalRxReportTemplate from '../../../../itrust_common/containers/authorised/setting/patient/report/reportTypes/ReportTemplate';
import Alert from '../../../../itrust_common/components/Alert'
import SecureFaxForm from '../../secureFax/SecureFaxForm';
import { maskDateInput} from '../../../../itrust_common/components/HelperFunctions'

class MedicalRxView extends Component{
  constructor(props){
    super(props)
    this.handleMedicalRxsLoad({ examination_id: this.props.pprops.initialValues.id, rpp: 999999})
  }

  handleMedicalRxsLoad(filters){
    this.props.dispatch(medicalRxsLoad(this.props.patientId, filters))
  }

  handleMedicalRxSubmit(data) {
    this.props.dispatch(medicalRxUpdate(this.props.patientId, data))
  }

  handleMedicalRxDelete(id){
    Alert.deleteConfirm(
      "Are you sure? You want to delete this record.",
      (response)=>{
        if(response)
        this.props.dispatch(medicalRxDelete(this.props.patientId, id))
      }
    )
  }

  handleMedicalRxSecureFax(data){
    this.props.dispatch(medicalRxSecureFax(this.props.patientId, data))
  }

  async handlePrintMedicalRx(patient_id, medical_rx_id){
    var res = await this.props.dispatch(patientReportPreview(patient_id, {report_type: "medical_rxes", object_id: medical_rx_id}))
    window.open(res.value.data.filepath,'_blank')
  }


  render(){
    const { medicalRxes, patientId, pprops: {patientReport: {display_fields, display_values}} } = this.props
    return (
      medicalRxes.map((rx, index) =>
        <div className="col-6 mb-7 medicalRx-scrollIntoView" id={`MedicalRx-${rx.id}`}>
          <div className="bg-white">
            <div className="d-flex align-items-center justify-content-between p-5 border-bottom border-bottom-2 border-light" >
              <h6>Medical Rx #{index+1}</h6>
              <div className="w-75 d-flex align-items-center">
                <SecureFaxForm form={`secureFaxForm-${rx.id}`} onSubmit={this.handleMedicalRxSecureFax.bind(this)} initialValues={{id: rx.id}}/>
                {/* <ExportButton label="Print" className="btn btn-primary mx-5" path={`/v1/patients/${patientId}/medical_rxes/${rx.id}/download_pdf`} /> */}
                <button type="button" className="btn btn-danger mx-5" onClick={this.handlePrintMedicalRx.bind(this, patientId, rx.id)}>Print</button>
                <button type="button" className="btn btn-danger" onClick={this.handleMedicalRxDelete.bind(this, rx.id)}>Remove</button>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between p-5 border-bottom border-bottom-2 border-light" >
              <div className="d-flex  align-items-center">
                <span className="mr-5">Issue Date</span>
                <DatePicker defaultValue={rx.issue_date && new Date(rx.issue_date.replace(/-/g, '\/'))} format="MM/DD/YYYY" editFormat="MM/DD/YYYY" placeholder="MM/DD/YYYY" onChange={(date) => this.handleMedicalRxSubmit({ id: rx.id, issue_date: date })} onKeyDown={e => e.keyCode === 191 && e.preventDefault()} onKeyUp={e => maskDateInput(e)}/>
              </div>
              <div className="d-flex  align-items-center">
                <span className="mr-5">Expiry Date</span>
                <DatePicker defaultValue={rx.expiry_date && new Date(rx.expiry_date.replace(/-/g, '\/'))} format="MM/DD/YYYY" editFormat="MM/DD/YYYY" placeholder="MM/DD/YYYY" onChange={(date) => this.handleMedicalRxSubmit({ id: rx.id, expiry_date: date })} onKeyDown={e => e.keyCode === 191 && e.preventDefault()} onKeyUp={e => maskDateInput(e)}/>
              </div>
            </div>
            <div className="p-7">
              <div className="border">
                <MedicalRxReportTemplate displayFields={display_fields} displayValues={display_values} category="medical_rx">
                  <div className="medical-rx d-flex flex-column justify-content-between py-6">
                    <div className="">
                      <div className="pb-4">{rx.medication}</div>
                      <div className="pt-4">Sig: {rx.dosage}</div>
                    </div>
                    { rx.is_refil_required !== true && <div className="pb-9">
                      <h6 className="pb-4">Comment: <span className="border-bottom border-bottom-2 border-secondary px-4 font-weight-normal">{rx.comments}</span></h6>
                      <h6 className="pb-4">Refill: <span className="border-bottom border-bottom-2 border-secondary px-4 font-weight-normal">{rx.refil_value}</span> times</h6>
                      <h6 className="pt-4">Generic: <span className="border-bottom border-bottom-2 border-secondary px-4 font-weight-normal">{rx.is_generic ? "Yes" : "No"}</span></h6>
                    </div> }
                  </div>
                </MedicalRxReportTemplate>
              </div>
              </div>
            </div>
          </div>
        )
    );
  }
}

const mapStateToProps = state => {
  const { medicalRx } = state;
  return {
    medicalRxes: medicalRx.medical_rxes,
    medicalRxEditing: medicalRx.editing
  }
}

export default connect(mapStateToProps)(MedicalRxView);

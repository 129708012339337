import React, { useEffect } from 'react'
import EyeMedClaimForm from '../../services/eyemed/claimForm/form/EyeMedClaimForm';
import { connect, useDispatch } from 'react-redux';
import { rpaClaimFormEdit, rpaClaimFormTemplateLoad } from '../../../../../../redux/actions/addOns/rpa/rpaClaimFormAction';

function RpaClaimForm(props) {
  const { order, rpaClaimFormTemplate } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(rpaClaimFormTemplateLoad())
    dispatch(rpaClaimFormEdit({ activeTab: 'first', activeModule: '' }))
  }, [])

  const getClaimFormTemplate = () => {
    // const service = services.find((item) => item.config.insurance_payer == order.patient_insurance_attributes?.insurance_name)
    return rpaClaimFormTemplate.find(item => item.service == order.vision_insurance_service?.service)
  }

  // const getServiceName = (serviceName) => {
  //   const service = services.find((item) => item.config.service == serviceName)
  //   return service.config.insurance_payer
  // }

  switch (order.vision_insurance_service?.service) {
    case 'eyemed':
      return <EyeMedClaimForm claimFormTemplate={getClaimFormTemplate()} />

    default:
      return ''
  }
}

const mapStateToProps = (state) => {
  const { order: { order }, rpaClaimForm: { rpaClaimFormTemplate } } = state
  return {
    // services,
    order,
    rpaClaimFormTemplate,
  }
}

export default connect(mapStateToProps)(RpaClaimForm);
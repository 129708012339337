import React, { Component } from 'react';
import { change, reduxForm } from 'redux-form';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as $ from 'jquery';

import { Select, DatePicker } from '../../../../itrust_common/components/form';
import { dateFormat, numberToCurrency, formatPhoneNumber, getCustomLabel, humanize, getShortName, getInsuranceType } from '../../../../itrust_common/components/HelperFunctions';

import { orderEdit, orderLoad, orderUpdate } from '../../../../redux/actions/order/orderActions';
import { claimFormLoad } from '../../../../redux/actions/order/claimFormActions';
import { tabChange } from '../../../../itrust_common/redux/actions/otherActions';
import { required } from '../../../../itrust_common/components/form/FormValidations';
import Alert from '../../../../itrust_common/components/Alert';
import OrderInsurancePayer from './OrderInsurancePayer';
import { rpaClaimFormLoad } from '../../../../redux/actions/addOns/rpa/rpaClaimFormAction';
import { getInsuranceFromRPAServices, isRpaVerifiedInsurance } from '../../../../containers/authorised/addOns/other/AddOnsHelperFunctions';
import RPASubmitHistory from '../../../../containers/authorised/addOns/rpa/eligibilityVerify/RPASubmitHistory';

let timeout = null;
class OrderHeaderForm extends Component {

  constructor(props) {
    super(props)
    if (!this.props.orderStaffId && this.props.storeDefaultProviderId) {
      this.props.dispatch(orderUpdate({ id: props.order.id, staff_id: props.storeDefaultProviderId }, { include: '*' }))
    }
  }

  handleOrderEdit(id) {
    this.props.dispatch(tabChange({ order: 1 }))
    this.props.dispatch(orderEdit(id));
    this.props.dispatch(orderLoad(id, { include: '*' }));
    // this.props.dispatch(claimFormLoad(id))
    this.handleClaimFormLoad(id)
  }

  async handleClaimTypeChange(claimType) {
    const { dispatch, order } = this.props
    if (claimType) {
      await dispatch(orderUpdate({ id: order.id, claim_type: claimType }, { include: '*' }))
      // dispatch(claimFormLoad(order.id))
      this.handleClaimFormLoad(order.id)
    }
    else {
      Alert.warning("Please select a valid insurance claim type.")
    }
  }

  handleClaimFormLoad(id) {
    const { order } = this.props
    if (order.rpa_claim_exist) {
      this.props.dispatch(rpaClaimFormLoad(id))
    }
    else {
      this.props.dispatch(claimFormLoad(id))
    }
  }

  getClaimTypeDropdownOptions(dropdownValuesObj) {
    return dropdownValuesObj && Object.keys(dropdownValuesObj).filter(key => dropdownValuesObj[key] === true);
  }

  render() {
    const { defaultVisible, handleSubmit, selectedOrderId, providers, allStaff, order, currentUser, handleClaimProviderSubmit, visionInsuranceTemplate, medicalInsuranceTemplate, orderStaffId, activeTab, claimFormData, rpaConfig, services } = this.props;

    // const isRpaSupported = isRpaVerifiedInsurance(rpaConfig, services, order.patient_insurance_attributes?.insurance_name)
    // const insuranceServiceType = getInsuranceFromRPAServices(services, order.patient_insurance_attributes?.insurance_name)?.config.service
    const noShowInsuranceFields = ["eyemed"].includes(order.vision_insurance_service?.service)
    const showInsuranceFields = order.vision_insurance_service?.service ? (claimFormData.id && !noShowInsuranceFields) : !!claimFormData.id;

    return (
      <form onSubmit={handleSubmit}>
        <div className="panel-group" id="accordion">
          <div className="panel panel-default">
            <div id="collapseOne" className={`panel-collapse collapse ${defaultVisible ? 'show' : ''}`}>
              <div className="panel-body">
                <div className="bg-white d-flex rounded shadow-sm my-5">
                  <div className="border-right border-light text-center p-5">
                    {getShortName(order.patient_attributes, 'mr-5 width-6 height-6', 'mr-5 width-6 height-6 font-size-40 ')}
                    <h5 className="mt-2 text-nowrap">
                      <Link to={`/patients/${order.patient_attributes.id}/overview`} className="btn-link" onClick={() => $('#orderForm-modal').modal('hide')}>{order.patient_attributes.name}</Link>
                    </h5>
                  </div>
                  <div className="p-lg-6 p-2">
                    <div>
                      <span className="text-nowrap">DOB & Age:</span>
                      <span className="text-muted pl-3 text-nowrap">{dateFormat(order.patient_attributes.date_of_birth)} | {order.patient_attributes.age}</span>
                    </div>
                    <div>
                      <span>Phone:</span>
                      <span className="text-muted text-muted pl-3 text-nowrap">{formatPhoneNumber(order.patient_attributes.cell_phone)}</span>
                    </div>
                    <div>
                      <span>Email:</span>
                      <span className="text-muted text-muted pl-3 text-nowrap">{order.patient_attributes.email}</span>
                    </div>
                    <div>
                      <span className="text-nowrap">{visionInsuranceTemplate && getCustomLabel(visionInsuranceTemplate, 'insurance')}:</span>
                      <span className="text-muted text-nowrap text-muted pl-3 text-nowrap">{order.patient_attributes.patient_vision_insurance && order.patient_attributes.patient_vision_insurance.insurance_name}</span>
                    </div>
                    <div>
                      <span className="text-nowrap">{medicalInsuranceTemplate && getCustomLabel(medicalInsuranceTemplate, 'insurance')} :</span>
                      <span className="text-muted text-nowrap text-muted pl-3 text-nowrap">{order.patient_attributes.patient_medical_insurance && order.patient_attributes.patient_medical_insurance.insurance_name}</span>
                    </div>
                  </div>
                  <div className="border-left border-light py-lg-5 py-2 px-lg-7 px-2 w-100">
                    <div className="row">
                      <div className="col-8">
                        <div className="row">
                          <Field name="staff_id" label="Created By"
                            component={Select} data={allStaff}
                            placeholder={!orderStaffId && 'Select Provider'}
                            textField="name" valueField="id"
                            wrapperClasses={`${(currentUser.role === 'non_admin' || currentUser.role === 'admin') && 'disabled'} form-group col-4`} />
                          {currentUser.role !== 'non_admin' &&
                            <Field name='order_date' label="Order Date" validate={[required]} component={DatePicker} wrapperClasses='form-group col-lg-4 col-4' />
                          }
                          <div className="col-lg-4 col-4">
                            <div className="form-group">
                              <label htmlFor="orderpleFormControlSelect2">Order Selection </label>
                              <select className="form-control" value={selectedOrderId} id="orderpleFormControlSelect2" onChange={(e) => this.handleOrderEdit(e.target.value)} >
                                {order.all_orders && order.all_orders.map((order) =>
                                  <option value={order.id}>{dateFormat(order.order_date)}</option>
                                )}
                              </select>
                            </div>
                          </div>
                          {currentUser.role !== 'non_admin' &&
                            <div className='col-lg-4 col-4'>
                              <Field name='promised_date' component={DatePicker} wrapperClasses='mt-1 mb-2 ' label={`Promised Date ${(order.days_until_promised_date || order.days_until_promised_date === 0) ? `(Days left: ${order.days_until_promised_date})` : ''}`} />
                            </div>
                          }
                          {showInsuranceFields &&
                            <>
                              <div className="col-4">
                                <div className="form-group">
                                  <label htmlFor="orderinsuranceclaimprovider">Insurance Claim Provider</label>
                                  <select className="form-control" value={claimFormData.claim_provider_id} id="orderinsuranceclaimprovider" onChange={(e) => handleClaimProviderSubmit({ id: claimFormData.id, claim_provider_id: e.target.value })} >
                                    <option value="" className="text-muted">Select Provider</option>
                                    {providers.map((provider) =>
                                      <option value={provider.id}>{provider.name}</option>
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label htmlFor="insuranceClaimType">Insurance Claim Type</label>
                                  <select className="form-control" value={order.claim_type} id="insuranceClaimType" onChange={(e) => this.handleClaimTypeChange(e.target.value)} >
                                    <option value="" className="text-muted">Select Claim Type</option>
                                    {this.getClaimTypeDropdownOptions(order.claim_dropdown_values)?.map((claimType) =>
                                      <option value={claimType}>{humanize(claimType)}</option>
                                    )}
                                  </select>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                      <div className="col-4">
                        <div className='row align-items-center'>
                          <OrderInsurancePayer claimFormData={claimFormData} dispatch={this.props.dispatch} activeTab={activeTab} />
                        </div>
                        <RPASubmitHistory key={order.patient_insurance_attributes?.expired_vision_insurance_logs} insuranceType={getInsuranceType(order.patient_insurance_attributes?.insurance_type)} wrapperClasses="px-0 col-12" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}


OrderHeaderForm = reduxForm({
  form: 'orderHeaderForm',
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    if (props.dirty) {
      clearTimeout(timeout);
      timeout = setTimeout(() => props.submit(), 300);
    }
  },
})(OrderHeaderForm)

OrderHeaderForm = connect(state => {
  const { currentStore, order, order: { order: { patient_insurance_attributes } }, staff, patient, auth, setting: { patientTemplate: { patient_template } }, organisation: { organisation }, rpa: { services } } = state
  const patientInsuranceData = patient_template.personal_information?.insurance_detail?.fields
  const patient_insurance_name = patient_insurance_attributes && patient_insurance_attributes.is_archived && `${patient_insurance_attributes.insurance_name}(Archived)`

  return {
    visionInsuranceTemplate: patientInsuranceData?.vision_insurance?.fields,
    medicalInsuranceTemplate: patientInsuranceData?.medical_insurance?.fields,
    order: order.order,
    patient: patient.patient,
    providers: currentStore.staffs,
    allStaff: staff.staffs,
    selectedOrderId: order.editing && order.editing.id,
    currentUser: auth.user,
    orderStaffId: order.order.staff_id,
    storeDefaultProviderId: currentStore.store.default_provider_id,
    rpaConfig: organisation.add_ons_config?.type?.rpa,
    services,
    initialValues: {
      id: order.order.id,
      staff_id: order.order.staff_id ? order.order.staff_id : currentStore.store.default_provider_id,
      order_date: order.order.order_date,
      promised_date: order.order.promised_date,
      patient_insurance_id: order.order.patient_insurance_id,
      patient_insurance_name: patient_insurance_name
    }
  }
})(OrderHeaderForm)

export default OrderHeaderForm;

import React from 'react';
import { Field, change } from 'redux-form';

import { Input, InputWithIcon, AutoCompleteSelect } from '../../../../../../itrust_common/components/form';

import DiscountField from '../../DiscountField';
import { connect } from 'react-redux';

let Frame = props => {
  const { member, currentEyeglass, pprops, orderStoreId } = props;

 function handleAddRandomBrand(randomBrand, handleAutoCompleteSelectState) {
    if (currentEyeglass.frame_id) {
      handleAutoCompleteSelectState({ selectedData: [] })
      pprops.dispatch(change('orderEyeglassForm', `${member}[frame_id]`, null))
      pprops.dispatch(change('orderEyeglassForm', `${member}[frame_details][brand]`, randomBrand))
      setTimeout(() => pprops.submit(), 500)
    }
    else {
      pprops.dispatch(change('orderEyeglassForm', `${member}[frame_details][brand]`, randomBrand))
      setTimeout(() => pprops.submit(), 500)
    }
  }

  const getStoreIdForSearch = () => {
    let StoreId = localStorage.getItem('StoreID')
    if (!StoreId && StoreId == null) {
      return orderStoreId
    }
    return StoreId
  }

  return (
    <div className="row mx-0 mb-7 bg-light">
      <div className="col-12 p-5">
        <h6>Frame</h6>
      </div>

      <Field
        name={`${member}[frame_id]`}
        displayName={`${member}[frame_details][brand]`}
        component={AutoCompleteSelect}
        onChangeHook={(data) => {pprops.dispatch(change('orderEyeglassForm', `${member}[frame_id]`, data.id)); setTimeout(() => pprops.submit(), 500) } }
        klass="Settings::EyeglassLensFrame"
        additionalFilters={{ store_inventory: getStoreIdForSearch()}}
        textField="brand"
        valueField="id"
        placeholder="Placeholder"
        wrapperClasses="col-4 form-group"
        className="form-control"
        label={
          <span>Frame { currentEyeglass.frame_amount_on_hand !== null && <span className="badge badge-info badge-pill" style={{height: 26}}> {currentEyeglass.frame_amount_on_hand} </span> } </span>
        }
        labelClasses="w-100"
        showAddOption={true}
        addSearchValSubmit={(val, handleAutoCompleteSelectState) => handleAddRandomBrand(val, handleAutoCompleteSelectState)  }
        isNilInputValue={true}
      />
      <Field name={`${member}[frame_unit_price]`} component={InputWithIcon} title="$" label="Unit Price" labelClasses="w-100" className={`form-control ${currentEyeglass.frame_id && 'bg-light disabled'}`} wrapperClasses="col-2 form-group" />
      <Field name={`${member}[frame_copay]`} component={InputWithIcon} title="$" label="Copay" labelClasses="w-100" className="form-control" wrapperClasses="col-2 form-group" />
      <div className="col-3">
        <label>Discount</label>
        <DiscountField
          cashName={`${member}[frame_cash_discount]`}
          percentageName={`${member}[frame_percentage_discount]`}
          discountTypeName={`${member}[frame_discount_type]`}
          defaultType={currentEyeglass.frame_discount_type}
          prependClasses="py-2"
        />
      </div>

      <Field name={`${member}[frame_details][model]`} component={Input} label="Model" className="form-control" wrapperClasses="col-3 form-group" />
      <Field name={`${member}[frame_details][material]`} component={Input} label="Material" className="form-control" wrapperClasses="col-3 form-group" />
      <Field name={`${member}[frame_details][color]`} component={Input} label="Color" className="form-control" wrapperClasses="col-3 form-group" />
      <Field name={`${member}[frame_details][eye_size]`} component={Input} label="Eye Size" className="form-control" wrapperClasses="col-3 form-group" />
      <Field name={`${member}[frame_details][bridge]`} component={Input} label="Bridge" className="form-control" wrapperClasses="col-3 form-group" />
      <Field name={`${member}[frame_details][temple]`} component={Input} label="Temple" className="form-control" wrapperClasses="col-3 form-group" />
      <Field name={`${member}[frame_details][b_glass]`} component={Input} label="B" className="form-control" wrapperClasses="col-3 form-group" />
      <Field name={`${member}[frame_details][ed]`} component={Input} label="ED" className="form-control" wrapperClasses="col-3 form-group" />
    </div>
  )
}

const mapStateToProps = (state) => {
  const { order: { order } } = state
  return {
    orderStoreId: order.store_id
  }
}

export default connect(mapStateToProps)(Frame);
import React, { useEffect, useState } from 'react'
import { patientLoad } from '../../../../../redux/actions/patient/patientActions'
import { connect, useDispatch } from 'react-redux'
import { rpaDetailView, rpaEligibilityReset, rpaEligibilityVerify } from '../../../../../redux/actions/addOns/rpa/rpaAction'
import { getInsuranceTypeBEName } from '../../../../../itrust_common/components/HelperFunctions'
import { orderLoad } from '../../../../../redux/actions/order/orderActions'
import { isDirty, submit } from 'redux-form'
import { getInsuranceFromRPAServices, isRpaVerifiedInsurance } from '../../other/AddOnsHelperFunctions'
import { RenderRPAService } from './RenderRPAServiceButton'

function RPAInsuranceVerify(props) {
  const { patientId, orderId, patient, selectedInsuranceName, currentStoreId, insuranceType, services, isOrderView, isPatientView, isPatientFormDirty, rpaConfig, order } = props
  const [insuranceVerifying, setInsuranceVerifying] = useState(false)
  const [showVerifyBtn, setShowVerifyBtn] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    // Order Patient Load
    patientId && dispatch(patientLoad(patientId, { include: '*' }))
    return () => {
      dispatch(rpaEligibilityReset())
    }
  }, [])

  useEffect(() => {
    dispatch(rpaEligibilityReset())
  }, [orderId])

  useEffect(() => {
    selectedInsuranceName && dispatch(rpaEligibilityReset())
  }, [selectedInsuranceName])

  const getData = (data) => {
    // default structure
    let storeId = currentStoreId ?? (orderId && order.store_id)
    const insurance = getInsuranceFromRPAServices(services, selectedInsuranceName)
    data = { ...data, patient_id: patient.id, add_ons_id: insurance.id, insurance_payer: selectedInsuranceName, service: insurance.config.service, store_id: storeId, insurance_type: getInsuranceTypeBEName(insuranceType), other_details: { resource: "verify_eligibility", member_id: data.member_id } }
    // structure as per Order Verification
    data = { ...data, orders_order_id: isOrderView ? orderId : null }
    return data
  }

  const handleBeforeVerify = (data) => {
    if (isPatientView && isPatientFormDirty) {
      dispatch(submit("patientForm"))
      setTimeout(() => {
        let invalidInputs = document.getElementsByClassName("is-invalid")
        invalidInputs.length === 0 && handleVerify(data)
      }, 500)
    }
    else {
      handleVerify(data)
    }
  }

  const handleInsuranceVerifySubmit = (data) => {
    data = getData(data)
    handleBeforeVerify(data)
  }

  const handleVerify = (data) => {
    setInsuranceVerifying(insuranceType)
    dispatch(rpaEligibilityVerify(data)).then((res) => {
      res = res.value.data
      handleOnVerifySuccess(res)
    }).catch(() => {
      setInsuranceVerifying(false)
      setTimeout(() => setShowVerifyBtn(false), 7000);
      handleLoadUpdatedLogs()
    })
  }

  const handleOnVerifySuccess = (res) => {
    dispatch(rpaDetailView({ visible: true, file_url: res.rpa_log.eligibility_file_url, service: res.rpa_log.service }))
    setInsuranceVerifying(false)
    setTimeout(() => setShowVerifyBtn(false), 7000);
    handleLoadUpdatedLogs()
  }

  const handleLoadUpdatedLogs = () => {
    // load to get verify new logs
    isOrderView && dispatch(orderLoad(orderId, { include: '*' }));
    isPatientView && dispatch(patientLoad(patient.id, { include: '*' }));
  }

  return (
    <>
      {isRpaVerifiedInsurance(rpaConfig, services, selectedInsuranceName) && <RenderRPAService pprops={{ ...props, handleInsuranceVerifySubmit, insuranceVerifying, showVerifyBtn, setShowVerifyBtn }} />}
    </>
  )
}


const mapStateToProps = (state) => {
  const { patient: { patient }, currentStore: { store }, rpa: { services }, organisation: { organisation } } = state

  return {
    patient,
    currentStoreId: store.id,
    services,
    isPatientFormDirty: isDirty('patientForm')(state),
    rpaConfig: organisation.add_ons_config?.type?.rpa,
  }
}

export default connect(mapStateToProps)(RPAInsuranceVerify)
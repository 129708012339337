import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { submit } from 'redux-form'
import Alert from '../../../../../../itrust_common/components/Alert'
import * as $ from 'jquery';
import { getInsuranceFromRPAServices } from '../../../other/AddOnsHelperFunctions'
import { rpaClaimFormSubmit } from '../../../../../../redux/actions/addOns/rpa/rpaClaimFormAction'
import { rpaServicesLoad } from '../../../../../../redux/actions/addOns/rpa/rpaAction'

function RpaClaimSubmitButton(props) {
  const { rpaSubmitting, order, rpaConfig, services, rpaClaimForm } = props
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    let storeID = localStorage.getItem('StoreID')
    if (storeID == null) {
      // Store dropdown All selected > load RPA services from order's store_id
      dispatch(rpaServicesLoad({ store_id: order.store_id, category: "rpa" }))
    }
  }, [])

  const handleRpaClaimSubmit = () => {
    if (rpaConfig?.status !== 'inactive') {
      if (rpaClaimForm.ready_to_submit) {
        submitClaim()
      }
      else {
        Alert.warning("Your claim form isn't ready to submit yet. Please complete the form filling process.")
      }
    }
    else {
      history.push('/add-ons/rpa/dashboard')
      $('#orderForm-modal').modal('hide');
    }
  }

  const submitClaim = () => {
    const insurance = getInsuranceFromRPAServices(services, order.patient_insurance_attributes?.insurance_name)
    dispatch(submit("1500ClamForm"))
    setTimeout(() => {
      let invalidInputs = (document.getElementsByClassName("is-invalid")?.length || document.getElementsByClassName("invalid-feedback")?.length)
      if (invalidInputs) {
        Alert.warning("Please fill the required information.")
      } else {
        dispatch(rpaClaimFormSubmit({ add_ons_id: insurance.id, orders_order_id: order.id, patient_id: order.patient_id, insurance_type: order.patient_insurance_attributes?.insurance_type, store_id: order.store_id, insurance_payer: order.patient_insurance_attributes?.insurance_name, service: insurance.config.service, other_details: { resource: "submit_claim" } }))
      }
    }, 500)
  }

  return (
    <>
      <button className="btn btn-outline-primary mr-lg-7 mr-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" onClick={handleRpaClaimSubmit}>
        {rpaSubmitting ? 'Sending...' : `Send to ${order.patient_insurance_attributes?.insurance_name}`}
      </button>
    </>
  )
}
const mapStateToProps = (state) => {
  const { organisation: { organisation }, rpa: { services }, rpaClaimForm: { rpaClaimForm, submitting } } = state

  return {
    rpaSubmitting: submitting,
    rpaConfig: organisation.add_ons_config?.type?.rpa,
    services,
    rpaClaimForm,
  }
}

export default connect(mapStateToProps)(RpaClaimSubmitButton)

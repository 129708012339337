import React, { Component } from "react";
import { connect } from 'react-redux';
import "./assets/scripts";
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import PublicRoutes from './routes/public';
import AuthorisedRoutes from './routes/authorised';
import PdfRoutes from './routes/pdf';
import PatientRoutes from './routes/authorised/patient';
import { getBackground, getCurrentZoomLevel, removeBackground } from './itrust_common/components/HelperFunctions'

import Toastr from './itrust_common/components/Toastr';
import Alert from './itrust_common/components/Alert';
import Banner from './components/others/Banners';
import NewVersionPopUp from './itrust_common/components/others/NewVersionPopUp';
// import IdleTimer from 'react-idle-timer'
import * as qs from 'query-string';
import { currentUserLoad, logout } from './itrust_common/redux/actions/authActions';

//preload data
import { staffStoresLoad } from './redux/actions/staffStoreActions'
import { examTemplatesLoad } from './itrust_common/redux/actions/settingActions/examTemplateActions';
import { recommendationsLoad } from './itrust_common/redux/actions/settingActions/recommendationActions';
import { contactLensCleaningSolutionsLoad } from './itrust_common/redux/actions/settingActions/contactLensCleaningSolutionActions';
import { patientTemplateLoad } from './itrust_common/redux/actions/settingActions/patientTemplateActions'
import { calendarSettingLoad } from './itrust_common/redux/actions/settingActions/calendarSettingActions';
import { organisationLoad } from "./redux/actions/organisationActions";
import { devicePaymentLogDelete, devicePaymentLogLoad } from "./redux/actions/order/orderPaymentActions";
import AddOns from "./containers/authorised/addOns/AddOns";
import OnBoardingTour from "./containers/authorised/onboarding/OnBoardingTour";

class App extends Component {
  constructor(props) {
    super(props);
    this.handleAssignToken()
    if (localStorage.getItem('Access-Token')) {
      this.currentUserLoad()
    }
    this.idleTimer = null
    this.state = {
      isLoggedIn: true
    }
    window.onload = async function () {
      let deviceLogId = localStorage.getItem('deviceLogId')
      if (deviceLogId) {
        let res = await props.dispatch(devicePaymentLogLoad(deviceLogId))
        res = res.action.payload.data["add_ons/itrust_pay/log"]
        if (res.status == 'initiated') {
          // terminal payment inProgress > handled screen reload and internet disconnect cases
          props.dispatch(devicePaymentLogDelete({ id: localStorage.getItem('deviceLogId') })).then(() => localStorage.removeItem('deviceLogId'))
        }
        else {
          localStorage.removeItem('deviceLogId')
        }
      }
    }
  }

  handleAssignToken() {
    // instant access orgs case
    const url = window.location.search;
    const queryParams = new URLSearchParams(url);
    const token = queryParams.get('token');
    if (token && window.location.pathname !== '/itrust-pay/payment') {
      // eliminate itrustPay link
      localStorage.setItem('Access-Token', token);
    }
  }

  preloadData(currentUser) {
    if (currentUser.type) {
      if (currentUser && currentUser.role === 'non_admin' && currentUser.accessible_stores && !localStorage.getItem('StoreID')) {
        localStorage.setItem('StoreID', currentUser.accessible_stores[0].store_id);
      }
      (currentUser.type !== "Patient") && this.props.dispatch(staffStoresLoad(currentUser.id, { include: ['store'] }))
      this.props.dispatch(examTemplatesLoad({ sort: 'position', direction: "asc", rpp: 9999 }))
      this.props.dispatch(recommendationsLoad({ rpp: 9999 }))
      this.props.dispatch(contactLensCleaningSolutionsLoad())
      this.props.dispatch(patientTemplateLoad())
      this.props.dispatch(calendarSettingLoad())
      this.props.dispatch(organisationLoad({ include: ['address'] }))
    }
  }

  getZoomClass() {
    let zoomLevel = getCurrentZoomLevel()
    switch (zoomLevel) {
      case '80%':
        return 'xsm-zoom';
      case '90%':
        return 'sm-zoom'
      case '105%':
        return 'lg-zoom';
      case '110%':
        return 'xl-zoom'
      case '115%':
        return 'xl-xl-zoom';
      default:
        return 'default-zoom'
    }
  }

  currentUserLoad() {
    this.props.dispatch(currentUserLoad({ include: ['address'] }))
  }

  handleLogout() {
    this.props.dispatch(logout())
    this.setState({ isLoggedIn: false })
  }

  // componentDidUpdate(nextProps) {
  //   if(nextProps.currentUser !==null){
  //     this.preloadData(nextProps.currentUser.id);
  //   }
  // }

  // handleOnIdle (event) {
  //   localStorage.setItem('Access-Token', null)
  // }

  componentDidUpdate() {
    const { props } = this
    if (props.currentUser !== null && this.state.isLoggedIn) {
      if (!this.props.isTourActive) { // block re-render on update onboarding_guide currentUser
        this.preloadData(props.currentUser);
        setTimeout(function () {
          getBackground(props.currentUser)
        }, 500)
      }
    }
    else removeBackground()
  }

  render() {
    const { currentUser, currentStoreId, appFontSize, isReplayTour } = this.props
    const isLogged = localStorage.getItem('Access-Token') ? true : false;
    var link = window.location.pathname
    const parsed = qs.parse(window.location.search);

    return (
      <div className={`app ${appFontSize == "large" && 'large-font'} ${!isReplayTour && this.getZoomClass()}`} style={{ zoom: (!isReplayTour && getCurrentZoomLevel()) ? getCurrentZoomLevel() : '100%' }}>
        {/* <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 30 * 1}
          onIdle={this.handleOnIdle.bind(this)}
          debounce={250}
        /> */}
        <Toastr />
        <Alert />
        <NewVersionPopUp />
        <Router>
          {(currentUser?.type !== 'Patient' && isLogged) && <OnBoardingTour />}
          {["super_admin", "itrust_admin"].includes(currentUser?.role) && <Banner />}
          <AddOns />
          <div className="h-100">
            {parsed.pdfAccessToken ?
              <PdfRoutes />
              :
              isLogged ?
                currentUser.type === 'Staff' || currentUser.type === 'User' ?
                  <>
                    <AuthorisedRoutes key={`selected-store-id-${currentStoreId}`} userType={currentUser.type} currentUser={currentUser} handleLogout={this.handleLogout.bind(this)} />
                  </>
                  :
                  currentUser.id && [
                    link !== "/patient_surveys" && <Redirect exact from="/" to={`/patients/${currentUser.id}/overview`} />,
                    <PatientRoutes key={`selected-store-id-${currentStoreId}`} currentUser={currentUser} userType={currentUser.type} handleLogout={this.handleLogout.bind(this)} />
                  ]
                : (
                  <div className="h-100">
                    <PublicRoutes />
                  </div>
                )
            }
          </div>
          <span className='onboarding-process-popup'></span>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth, currentStore, onBoarding } = state;
  return {
    appFontSize: auth.user?.preferences?.accessibility?.font_appearance,
    currentUser: auth.user,
    currentDayHour: auth.currentDayHour,
    currentStoreId: currentStore.store.id,
    isTourActive: onBoarding.isTourActive,
  }
}

export default connect(mapStateToProps)(App);

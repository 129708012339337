import { currentUserUpdate } from "../../../itrust_common/redux/actions/authActions";
import { layoutChange } from "../../../itrust_common/redux/actions/otherActions";
import { proceduresLoad } from "../../../itrust_common/redux/actions/settingActions/procedureActions";
import { appointmentLoad } from "../../../redux/actions/appointmentActions";
import { appointmentCreate } from "../../../redux/actions/appointmentActions";
import { currentStoreLoad } from "../../../redux/actions/currentStoreActions";
import { examDelete } from "../../../redux/actions/exam/examActions";
import { onBoardingAppointmentsLoad, onBoardingPatientsLoad, onBoardingTourGuideInfoUpdate, onBoardingTourInfoReset, onBoardingTourInfoUpdate, onBoardingTourProgressInfoUpdate } from "../../../redux/actions/onBoardingActions";
import { orderDelete } from "../../../redux/actions/order/orderActions";
import { organisationUpdate } from "../../../redux/actions/organisationActions";
import { patientCreate } from "../../../redux/actions/patient/patientActions";
import { ActiveAccountSteps, DefaultOnboardingGuide, ExamSteps, OrderSteps, PatientSteps, AppointmentSteps } from "./StepsConstant";
import * as $ from 'jquery';
import Moment from 'moment';

// onBoarding Tour start, close and back
export const handleOnboardingTourStart = async (onBoardingTourData) => {
  const { dispatch, history, staff_stores, userId, userOnboardingGuide } = onBoardingTourData
  if (!localStorage.getItem('StoreID')) {
    localStorage.setItem('StoreID', staff_stores[0].store_id);
    dispatch(currentStoreLoad('', { include: '*' }))
  }
  await createDemoPatientForOnboarding(dispatch, userId)
  await dispatch(onBoardingTourGuideInfoUpdate(DefaultOnboardingGuide))
  !userOnboardingGuide?.is_tour_taken_once && await dispatch(currentUserUpdate({ onboarding_guide: { is_tour_taken_once: true } }))
  dispatch(onBoardingTourProgressInfoUpdate({ checkListShow: true, moduleProgressHide: false }))
  handleAppointmentTourPlay(dispatch, history)
}

export const handleAppointmentTourPlay = (dispatch, history) => {
  if (history.location.pathname !== '/appointment') {
    history.push('/appointment')
    setTimeout(() => {
      dispatch(onBoardingTourInfoUpdate({ stepIndex: 0, isTourActive: true, activeTourType: 'create_appointment', nextModuleToPlay: 'create_appointment' }))
      dispatch(onBoardingTourProgressInfoUpdate({ checkListShow: true }))
    }, 500);
  }
  else {
    dispatch(onBoardingTourInfoUpdate({ stepIndex: 0, isTourActive: true, activeTourType: 'create_appointment', nextModuleToPlay: 'create_appointment' }))
    dispatch(onBoardingTourProgressInfoUpdate({ checkListShow: true }))
  };
}

export const handleStartNewModule = async (onBoardingTourData) => {
  const { onBoarding: { nextModuleToPlay, progressPopUpInfo: { demoPatientAppointments } }, dispatch, history, userRole, layout } = onBoardingTourData
  if (nextModuleToPlay && nextModuleToPlay !== 'activate_account' && layout !== "default") {
    dispatch(layoutChange('default'))
  }
  switch (nextModuleToPlay) {
    case 'create_appointment':
      handleAppointmentTourPlay(dispatch, history)
      break;
    case 'create_patient':
      dispatch(onBoardingTourInfoUpdate({ stepIndex: 0, isTourActive: true, activeTourType: 'create_patient' }))
      break;
    case 'create_exam':
      await handleOrderExamDelete(dispatch, demoPatientAppointments)
      history.push('/dashboard/overview')
      dispatch(onBoardingTourInfoUpdate({ activeTourType: 'create_exam' }))
      setTimeout(() => dispatch(onBoardingTourInfoUpdate({ stepIndex: 0, isTourActive: true, activeTourType: 'create_exam' })), 1000);
      break;
    case 'create_order':
      await handleOrderExamDelete(dispatch, demoPatientAppointments)
      history.push('/dashboard/overview')
      setTimeout(() => dispatch(onBoardingTourInfoUpdate({ stepIndex: 0, isTourActive: true, activeTourType: 'create_order' })), 1000);
      break;
    case 'activate_account':
      if (["super_admin", "itrust_admin"].includes(userRole)) {
        if (layout !== "default") {
          dispatch(layoutChange('default'))
          history.push('/dashboard/overview')
        }
        dispatch(onBoardingTourInfoUpdate({ activeTourType: 'activate_account' }))
        setTimeout(() => dispatch(onBoardingTourInfoUpdate({ stepIndex: 0, isTourActive: true, activeTourType: 'activate_account' })), 700);
      }
      else {
        dispatch(onBoardingTourInfoReset())
      }
      break;
    default:
      dispatch(onBoardingTourInfoReset())
      break;
  }
}

export const handleCloseCurrentModule = (onBoardingTourData) => {
  const { onBoarding: { activeTourType, stepIndex } } = onBoardingTourData
  switch (activeTourType) {
    case 'create_appointment':
      stepIndex === 1 && $('#appointmentForm-modal').modal('hide')
      break;
    case 'create_patient':
      stepIndex === 2 && $('#patientForm-modal').modal('hide');
      break;
    case 'create_exam':
      $('#newExamForm-modal').modal('hide');
      break;
    case 'activate_account':
      $('#newExamForm-modal').modal('hide');
      break;
    default:
      break;
  }
}

export const handleTourBackStep = (onBoardingTourData, data) => {
  const { dispatch, onBoarding: { activeTourType, stepIndex }, history } = onBoardingTourData
  switch (activeTourType) {
    case 'create_appointment':
      if (stepIndex === 3) {
        history.push('/appointment')
        setTimeout(() => dispatch(onBoardingTourInfoUpdate({ stepIndex: 0, isTourActive: true, activeTourType: 'create_appointment' })), 200);
      }
      break;
    case 'create_patient':
      data.type === 'step:after' && dispatch(onBoardingTourInfoUpdate({ stepIndex: 0, isTourActive: true, activeTourType: 'create_patient' }))
      break;
    case 'activate_account':
      dispatch(layoutChange('default'))
      history.push('/dashboard/overview')
      setTimeout(() => dispatch(onBoardingTourInfoUpdate({ stepIndex: 0, isTourActive: true, activeTourType: 'activate_account' })), 200);
      break;
    default:
      break;
  }
}

// Demo Patient Create, Load and its Appointment Create, Load
export const createDemoPatientForOnboarding = async (dispatch, userId) => {
  let patients = await dispatch(onBoardingPatientsLoad({ search: 'Demo Patient' }))
  patients = patients.value.data.patients
  // check if demo patient exist
  if (patients.length > 0) {
    return createDemoPatientAppointment(dispatch, patients[0].id, userId)
  }
  else {
    let patient = await dispatch(patientCreate({ first_name: 'Demo Patient' }))
    patient = patient.value.data.patient
    return createDemoPatientAppointment(dispatch, patient.id, userId)
  }
}

export const createDemoPatientAppointment = async (dispatch, patient_id, userId) => {
  let appointments = await handleAppointmentsLoad(dispatch, patient_id)
  let today = Moment(new Date()).format("YYYY-MM-DD")
  let isTodayAppointmentExist = appointments.some(appointment => appointment.start_day === today);
  if (!isTodayAppointmentExist) {
    // create appointment only if appointment doesn't exist
    const appointmentData = {
      store_id: localStorage.getItem('StoreID'), staff_id: userId, appointment_type: 'in_office', start_time: new Date(), end_time: new Date(new Date().getTime() + 600000), start_day: Moment(new Date()).format("YYYY/MM/DD").toString(), end_day: Moment(new Date()).format("YYYY/MM/DD").toString()
    }
    let procedures = await dispatch(proceduresLoad({ rpp: 2 }))
    procedures = procedures.value.data.procedures
    let appointment = await dispatch(appointmentCreate({ patient_id: patient_id, procedure_ids: [procedures[0].id, procedures[1].id], ...appointmentData }, { include: ['patient', 'staff', 'procedures'] }))
    appointment = appointment.value.data.appointment
    dispatch(onBoardingTourProgressInfoUpdate({ demoPatientAppointments: [appointment] }))
    return appointment
  }
  else {
    dispatch(onBoardingTourProgressInfoUpdate({ demoPatientAppointments: appointments }))
    return appointments
  }
}

// Order Exam Delete (No need to create fresh appointment for exam and order tour)
export const handleOrderExamDelete = async (dispatch, appointments) => {
  // handled demo appointment Exam and Order delete
  appointments = await handleAppointmentsLoad(dispatch, appointments[0].patient_id)
  let today = Moment(new Date()).format("YYYY-MM-DD")
  appointments = appointments.filter(appointment => appointment.start_day === today);
  let appointment = appointments.find(item => (!item.examination_attributes && !item.order_attributes))
  // if today appointment exist then delete its exam and
  if (!appointment) {
    appointment = appointments.find(item => item.examination_attributes || item.order_attributes)
    // check if exam or order attr exist in appointment > delete it
    if (appointment.examination_attributes?.id) {
      await dispatch(examDelete(appointment.examination_attributes.id))
      appointment = await dispatch(appointmentLoad(appointment.id, { include: ['patient', 'staff', 'procedures', 'examination', 'order'] }))
      appointment = appointment.value.data.appointment
    }
    else {
      appointment.order_attributes?.id && await dispatch(orderDelete(appointment.order_attributes.id))
    }
  }
  dispatch(onBoardingTourProgressInfoUpdate({ demoPatientAppointmentId: appointment.id, }))
}

// Staff and Org onBoarding Guide Updates
export const handleUserOnBoardingGuideUpdate = (onBoardingTourData) => {
  const { onBoarding: { moduleTourCompleted }, dispatch, userRole } = onBoardingTourData
  dispatch(onBoardingTourGuideInfoUpdate({ [moduleTourCompleted]: true }))
  dispatch(onBoardingTourInfoUpdate({ moduleTourCompleted: false }))
  if (['super_admin'].includes(userRole)) {
    handleTourCompletionSubmit(onBoardingTourData)
  }
}

export const handleTourCompletionSubmit = (onBoardingTourData) => {
  const { onBoarding: { moduleTourCompleted }, dispatch, orgOnboardingDetails, userRole, userId, organisationStatus, onboardingGuide } = onBoardingTourData
  const totalSteps = (["super_admin", "itrust_admin"].includes(userRole) && organisationStatus === 'trial') ? 6 : 5
  let trueCount = Object.values(onboardingGuide)?.filter(val => val === true).length
  trueCount = (onboardingGuide[moduleTourCompleted] === true) ? trueCount : trueCount + 1
  const progress = Math.round((trueCount / totalSteps) * 100);
  if (progress > orgOnboardingDetails?.user_progress.tour_completion && orgOnboardingDetails?.user_progress.id == userId) {
    dispatch(organisationUpdate({ onboarding_details: { ...orgOnboardingDetails, user_progress: { ...orgOnboardingDetails?.user_progress, tour_completion: progress } } }))
  }
}

// General
export const getCurrentTourSteps = (activeTourType) => {
  switch (activeTourType) {
    case 'create_appointment':
      return AppointmentSteps
    case 'create_patient':
      return PatientSteps
    case 'create_exam':
      return ExamSteps
    case 'create_order':
      return OrderSteps
    case 'activate_account':
      return ActiveAccountSteps
    default:
      return []
  }
}

export const handleActiveTourOverlayArea = () => {
  const viewportHeight = window.innerHeight;
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
  const overlayHeight = Math.max(viewportHeight, documentHeight);
  let wrapper = document.getElementsByClassName('react-joyride__overlay')[0];
  if (wrapper) {
    wrapper.style.height = overlayHeight + 'px';
  }
}

const handleAppointmentsLoad = async (dispatch, patient_id) => {
  let appointments = await dispatch(onBoardingAppointmentsLoad({ rpp: 2, direction: "desc", sort: "start_time", badge_type: 'patient_profile_count_cards', filter: { patient: patient_id }, include: ['patient', 'staff', 'procedures', 'examination', 'order'] }))
  appointments = appointments.value.data.appointments
  // filter active appointments only
  appointments = appointments.filter(item => item.status !== 'cancelled')
  return appointments
}

import React, { useEffect } from 'react'
import { Field, FieldArray, change, formValueSelector } from 'redux-form'
import { humanize } from '../../../../../../../../../itrust_common/components/HelperFunctions';
import { Checkbox } from '../../../../../../../../../itrust_common/components/form';
import { mergeActiveFields } from '../../../eyeMedHelper';
import { connect, useDispatch } from 'react-redux';
import EyeMedFormButton from '../EyeMedFormButton';

function ExamClaimStep2(props) {
  const { claimFormTemplate, rpaClaimForm, saving, formImpression } = props
  const dispatch = useDispatch()

  const handleImpressionVal = (field) => {
    dispatch(change('EyeMedClaimForm', `exams.impression.${field.label}.selected_value`, mergeActiveFields({ claimFormTemplate: claimFormTemplate.config.exams.impression, rpaClaimForm: rpaClaimForm.exams?.impression, fieldName: field.label })))
    if (field.cpt_codes) {
      // cpt_codes
      dispatch(change('EyeMedClaimForm', `exams.impression.${field.label}.cpt_codes`, mergeActiveFields({ claimFormTemplate: claimFormTemplate.config.exams.impression, rpaClaimForm: rpaClaimForm.exams?.impression, fieldName: field.label }, true)))
    }
  }

  useEffect(() => {
    // set checkbox keyVal from template to form
    claimFormTemplate.config.exams.impression?.map((field => field.type === "checkbox" && handleImpressionVal(field)))
  }, [rpaClaimForm.exams?.impression, saving])

  const checkShowCptFields = (label) => {
    let fieldName = getImpressionField(label)
    const isCPTCodeExist = claimFormTemplate.config.exams.impression.find(item => item.label == fieldName)?.cpt_codes
    if (isCPTCodeExist) {
      // if cpt_codes exist for the field in template then show CPT fields
      return true
    }
    return false
  }

  function getImpressionField(label) {
    switch (label) {
      case 'Cataract':
        return 'cataract';
      case 'Glaucoma':
        return 'glaucoma';
      case 'Macular Degeneration':
        return 'macular_degeneration';
      case 'Type 1 Diabetes':
        return 'type_1_diabetes_complications';
      case 'Type 2 Diabetes':
        return 'type_2_diabetes';
      case 'Unspecified Diabetes':
        return 'unspecified_diabetes';
      case 'ARMD':
        return 'armd';
      case 'Abnormal Pupil':
        return 'abnormal_pupil';
      case 'Hypercholesterol':
        return 'hypercholesterol';
      case 'Hypertension':
        return 'hypertension';
      default:
        return '';
    }
  }

  const getDisabled = (field, label) => {
    let fieldsArr = ['macular_degeneration', 'cataract', 'glaucoma']
    field = field.split('.')[0]
    let isField = fieldsArr.includes(field)
    if (isField && formImpression[field]?.cpt_codes.find(item => item.label == 'Does Not Apply')?.active) {
      return label !== "Does Not Apply" ? true : false
    }
    return false
  }

  const handleCptChange = (e, field) => {
    const mainField = field.split('.')[0]; // Extract main field only once
    const isChecked = e.target.checked;
    const isDoesNotApply = e.target.labels[0]?.innerHTML === 'Does not apply';
    if (isChecked && isDoesNotApply) {
      const cptCodes = claimFormTemplate.config.exams.impression
        .find(item => item.label === mainField)?.cpt_codes || []; // Safely handle cases where find might return undefined
      cptCodes.forEach((code, index) => {
        if (code.label !== "Does Not Apply") {
          dispatch(change('EyeMedClaimForm', `exams.impression.${mainField}.cpt_codes[${index}].active`, false));
        }
      });
    }
  };

  const RenderCheckBox = (props) => {
    const { fields } = props;
    return (
      <>
        {fields.map((member, index, members) => <Field name={`${member}.active`} label={`${humanize(members.get(index).label)}`} component={Checkbox} wrapperClasses="form-group" />)}
      </>
    )
  }

  const RenderCptCheckBox = (props) => {
    const { fields } = props;
    return (
      <>
        {fields.map((member, index, members) => <Field name={`${member}.active`} label={`${humanize(members.get(index).label)}`} component={Checkbox} wrapperClasses="form-group" disabled={getDisabled(member, members.get(index).label)} onChange={(e) => handleCptChange(e, member)} />)}
      </>
    )
  }

  return (
    <div>
      {(rpaClaimForm.exams.exam.is_cpt2_selected == 'yes' && rpaClaimForm.exams.exam.cpt_2.length) ?
        <>
          {rpaClaimForm.exams.exam.cpt_2?.map((item) => {
            return <div className='row border-bottom pb-5 mb-5 mx-1'>
              <div className={`${checkShowCptFields(item.label) ? 'col-6' : 'col-12'}`}>
                <label className='mb-4 font-size-18 font-weight-500'>{humanize(item.label)}</label>
                <p className='text-muted'>Select one or more {humanize(item.label)} that apply.</p>
                <FieldArray
                  name={`${getImpressionField(item.label)}.selected_value`}
                  component={RenderCheckBox}
                />
              </div>
              {checkShowCptFields(item.label) && <div className='col-6'>
                <h6 className='mb-4'>CPT II Codes</h6>
                <FieldArray
                  name={`${getImpressionField(item.label)}.cpt_codes`}
                  component={RenderCptCheckBox}
                />
              </div>}
            </div>
          })}
        </> :
        <h5>No CPT II and Disease Reporting Diagnosis Selected </h5>
      }
      <div className='col-12'>
        <EyeMedFormButton backStep={2} backModule="Exam" />
      </div>
    </div>
  )
}

const selector = formValueSelector('EyeMedClaimForm');

const mapStateToProps = (state) => {
  const { rpaClaimForm: { saving } } = state
  const formImpression = selector(state, 'exams.impression')
  return {
    saving,
    formImpression,
  }
}

export default connect(mapStateToProps)(ExamClaimStep2)
import React from 'react'
import _ from 'lodash';
import { useOnboardingTourData } from '../OnBoardingCustomHooks';

export default function TourProgressBar(props) {
  const { moduleProgressHide } = props
  const onBoardingTourData = useOnboardingTourData()
  const { onboardingGuide, userRole, organisationStatus } = onBoardingTourData

  const calTourProgressBarStatus = () => {
    // Count the number of true values
    const trueCount = Object.values(onboardingGuide)?.filter(val => val === true).length
    // Total number of steps
    const totalSteps = (["super_admin", "itrust_admin"].includes(userRole) && organisationStatus === 'trial') ? 6 : 5
    // Calculate the progress percentage
    const progress = Math.round((trueCount / totalSteps) * 100);
    return progress < 100 ? progress : 100;
  }

  return (
    <div className={`${moduleProgressHide ? 'my-4' : 'my-6'}`}>
      <div className="progress">
        <div className="progress-bar font-size-18" role="progressbar" style={{ width: `${calTourProgressBarStatus()}%` }} aria-valuenow={`${calTourProgressBarStatus()}`} aria-valuemin="0" aria-valuemax="100">{`${calTourProgressBarStatus()}%`}</div>
      </div>
    </div>
  )
}

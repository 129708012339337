import React, { Component } from 'react';
import Moment from 'moment';
import moment from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import { maskDateInput } from '../HelperFunctions'
import Toastr from '../Toastr';

Moment.locale('en');
momentLocalizer();
const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export class DatePicker extends Component {

  handleValue(value) {
    return value.length > 20 ? new Date(value) : new Date(value.replace(/-/g, '\/'))
  }

  handleKeyUp(e) {
    const { input: { onChange }, onChangeHook } = this.props
    let enteredDate = e.target.value
    maskDateInput(e)
    if (enteredDate.length === 10 && new Date(enteredDate) == 'Invalid Date') {
      Toastr.handleShow(false, 'Enter a valid date')
      onChange('');
      if (onChangeHook) {
        onChangeHook('');
      }
    }
  }

  render() {
    const {
      props,
      props: { input: { onChange, value, open }, disabled, onChangeHook, wrapperClasses, label, timeSlotDuration, readOnly},
      props: { meta: {touched, error, warning}}
    } = this;

    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label>{props.label}</label>}
        <DateTimePicker
          {...props}
          onChange={val => {
            val = val ? Moment(val).format("YYYY/MM/DD") : '';
            onChange(val);
            if (onChangeHook) {
              onChangeHook(val);
            }
          }}
          onKeyDown={e => e.keyCode === 191 && e.preventDefault()}
          onKeyUp={e => this.handleKeyUp(e)}
          onFocus={open}
          time={false}
          value={(!value || value === "Invalid date") ? null : this.handleValue(value)}
          format="MM/DD/YYYY"
          editFormat="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
          step={timeSlotDuration}
          readOnly={readOnly}
        />
        { touched &&
          ((error && <span className="error-msg text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
        { touched && (error && <i className={`la la-warning text-danger ${label === undefined ? 'error-icon' : 'error-icon-label'}`}></i>)}
      </div>
    );
  }
}

let formatter = date =>
      date ? moment.tz(date, localTimeZone).format('MM/DD/YYYY hh:mm A z') : 'MM/DD/YYYY hh:mm A';

export class DateAndTimePicker extends Component {
  render() {
    const {
      props,
      props: { input: { onChange, value, open }, disabled, onChangeHook, wrapperClasses, label, timeSlotDuration, readOnly },
      props: { meta: { touched, error, warning } }
    } = this;
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label>{props.label}</label>}
        <DateTimePicker
          {...props}
          onChange={val => {
            onChange(val);
            if (onChangeHook) {
              onChangeHook(val);
            }
          }}
          onKeyDown={e => e.keyCode === 191 && e.preventDefault()}
          onKeyUp={e => maskDateInput(e)}
          onFocus={open}
          value={(!value || value==="Invalid date") ? null : new Date(value)}
          format={formatter}
          step={timeSlotDuration}
          readOnly={readOnly}
        />
        {touched &&
          ((error && <span className="invalid-feedback text-danger d-inline la-sm">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    );
  }
}

export class DatePickerIcon extends Component {
  render() {
    const { props } = this;
    return (
      <div>
        <DateTimePicker {...props} time={false} />
      </div>
    );
  }
}

import React from 'react'
import { humanize } from '../../../../../../../itrust_common/components/HelperFunctions'
import EyeMedFinalStep from './form/EyeMedFinalStep'
import { rpaClaimFormEdit } from '../../../../../../../redux/actions/addOns/rpa/rpaClaimFormAction'
import { useDispatch } from 'react-redux'

export default function ClaimFormOverview(props) {
  const { rpaClaimForm, claimFormTemplate } = props
  const activeModulesArr = rpaClaimForm.eligibility.data.map(item => item.active && item.service_type ? item.service_type : '')
  const dispatch = useDispatch()

  const getFieldData = (key, obj, templateObj) => {
    if (typeof obj[key] === 'object') {
      const fieldInfo = templateObj.find(item => item.label === key)
      const valLabel = fieldInfo?.options.find(item => item.value === obj[key].value)
      return valLabel ? valLabel.label : ''
    }
    else {
      return obj[key]
    }
  }

  const getCheckboxFieldData = (key, obj) => {
    let data = obj[key] ? obj[key].map(item => item.label) : []
    return data.map(item => <p className='mb-3 text-muted text-break'>{item}</p>)
  }

  const getImpressionCheckbox = (key, obj) => {
    let selectedValueArr = obj[key].selected_value.map(item => item.label)
    return selectedValueArr?.map(item1 => <p className='mb-3 text-muted text-break'>{item1}</p>)
  }

  const getImpressionCptCheckbox = (key, obj) => {
    if (obj[key].cpt_codes?.length) {
      let cptCodesArr = obj[key].cpt_codes.map(item => item.label)
      return <>
        <h6 className='mt-5 mb-4'>CPT II</h6>
        {cptCodesArr?.map(item => <p className='mb-3 text-muted text-break'>{item}</p>)}
      </>
    }
  }

  const ContactLensOverview = () => {
    // Contact lens Fit & Follow Up
    return (
      <div className='mb-6 pb-6 border-bottom'>
        <h5>Contact lens Fit & Follow Up</h5>
        <div className='row'>
          {rpaClaimForm.contact_lens_fit_and_follow_up && Object.keys(rpaClaimForm.contact_lens_fit_and_follow_up)?.map(key => <div className='col-6 mt-5'>
            <h6 className='mb-4'>{humanize(key)}</h6>
            <p className='mb-3 text-muted text-break'>{getFieldData(key, rpaClaimForm?.contact_lens_fit_and_follow_up, claimFormTemplate?.config.contact_lens_fit_and_follow_up)}</p>
          </div>)}
        </div>
      </div>
    )
  }

  const ExamOverview = () => {
    const nonCheckboxFields = claimFormTemplate.config.exams.exam.filter(item => item.type !== 'checkbox')
    const checkboxFields = claimFormTemplate.config.exams.exam.filter(item => item.type == 'checkbox')

    return (
      <div className='mb-5'>
        <h5>Exam</h5>
        <div className='row'>
          {checkboxFields?.map((item) => <div className='col-3 mt-5'>
            <h6 className='mb-4'>{humanize(item.label)}</h6>
            {getCheckboxFieldData(item.label, rpaClaimForm?.exams.exam)}
          </div>)}
          {nonCheckboxFields.map((item) => <div className='col-3 mt-5'>
            <h6 className='mb-4'>{humanize(item.label)}</h6>
            <p className='mb-3 text-muted text-break'>{getFieldData(item.label, rpaClaimForm?.exams.exam, claimFormTemplate?.config.exams.exam)}</p>
          </div>)}
          <div className='mt-5 col-12'>
            <h6 className=''>CPT II and Disease Reporting Diagnosis:</h6>
            <div className='row'>
              {Object.keys(rpaClaimForm.exams?.impression).map((item) => {
                return (rpaClaimForm?.exams.impression[item]?.selected_value?.length || rpaClaimForm?.exams.impression[item].cpt_codes?.length) ? <div className='col-3 mt-5'>
                  <h6 className='mb-4'>{humanize(item)}</h6>
                  {getImpressionCheckbox(item, rpaClaimForm?.exams.impression)}
                  {getImpressionCptCheckbox(item, rpaClaimForm?.exams.impression)}
                </div> : ''
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='mt-7'>
      <div className='bg-white p-6 border rounded'>
        <div className='d-flex justify-content-between'>
          <h3>Eyemed Insurance Claim Form</h3>
          <button className='btn btn-primary' onClick={() => dispatch(rpaClaimFormEdit({ formView: 'form', activeTab: 'first', activeModule: '' }))}>Edit Claim Form</button>
        </div>
        {claimFormTemplate && <div className='mt-7'>
          {activeModulesArr.includes('Contact Lens Fit and Follow-up') && <ContactLensOverview />}
          {activeModulesArr.includes('Exam') && <ExamOverview />}
        </div>}
        {rpaClaimForm.claim_data?.charges && <div className='my-7'>
          <h5 className='mb-5'>Charges</h5>
          <EyeMedFinalStep rpaClaimForm={rpaClaimForm} isOverview={true} />
        </div>}
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import BackgroundSelector from '../../../itrust_common/components/others/BackgroundSelector';
import NewFeaturePopup from '../../../other/NewFeaturePopup';
import Support from '../../../support/Support';
import Permission from '../../others/Permission.js';
import { hasKeyValueWithSkipKey } from '../../../itrust_common/components/HelperFunctions';
import PreferencesModal from '../perferences/PreferencesModal';
import { onBoardingTourInfoUpdate } from '../../../redux/actions/onBoardingActions';
import { useDispatch } from 'react-redux';
import { getAddOnDefaultServiceURL } from '../../../containers/authorised/addOns/other/AddOnsHelperFunctions';

let sidebarMenu = [
  { title: 'Dashboard', icon: 'la-dashboard', path: '/dashboard' },
  { title: 'Appointments', icon: 'la-calendar', path: '/appointment', moduleName: 'appointments', action: 'can_view' },
  { title: 'Exams', icon: 'la-stethoscope', path: '/exam', moduleName: 'exams', action: 'can_view' },
  { title: 'Orders', icon: 'la-shopping-cart', path: '/order', moduleName: 'orders', action: 'can_view' },
  { title: 'Patient', icon: 'la-user-plus', path: '/patients', moduleName: 'patients', action: 'can_view' },
  { title: 'Payments', icon: 'la-dollar-sign', path: '/payment-report', moduleName: 'payment_reports', action: 'can_view' },
  { title: 'Campaign', icon: 'la-comment', path: '/campaign', moduleName: 'campaigns', action: 'can_view' },
  { title: 'Logs', icon: 'la-file-text-o', path: '/logs', moduleName: 'logs', action: 'can_view' },
  /*{title:'Analytics',icon:'la-chart-line',path:'/analytics'},*/
  /*{title:'Reports',icon:'la-bar-chart',path:'/report'},*/
  // {title:'Store Settings',icon:'la-hospital-o',path:'/store-settings'},
  { title: 'Settings', icon: 'la-gear', path: '/settings', onlyAdmin: "super_admin" }
];

const Sidebar = (props) => {
  const { organisation, totalAmount, isTourActive } = props
  const [sideMenuBar, setSideMenuBar] = useState(sidebarMenu)
  const dispatch = useDispatch()

  const handleActivePatientTour = () => {
    if (isTourActive) {
      setTimeout(() => dispatch(onBoardingTourInfoUpdate({ stepIndex: 1, isTourActive: true })), 600);
    }
  }

  const getSideMenu = () => {
    let itrust_pay = organisation?.add_ons_config?.type?.itrust_pay
    const shouldIncludeAddOns = hasKeyValueWithSkipKey(organisation?.add_ons_config?.type, 'visible', true)
    if (shouldIncludeAddOns) {
      if (sidebarMenu[8].title !== 'Add-ons') {
        sidebarMenu.splice(-1, 0, {
          title: 'Add-ons',
          icon: 'las la-crown',
          path: `${getAddOnDefaultServiceURL(organisation?.add_ons_config?.type)}`,
          moduleName: 'addOns',
          action: 'can_view',
          badge: (itrust_pay?.visible && itrust_pay?.status !== 'inactive' && !itrust_pay?.account_info?.id && totalAmount) ? true : false
        });
      }
      return sidebarMenu
    }
    else {
      if (sidebarMenu[8].title === 'Add-ons') {
        sidebarMenu.splice(8, 1);
      }
      return sidebarMenu
    }
  }

  useEffect(() => {
    setSideMenuBar(getSideMenu())
  }, [organisation?.add_ons_config?.type, getSideMenu()])

  return (
    <>
      <div>
        <div className={`menubar bg-white text-center position-fixed h-100 border-right sidebar-height ${!isTourActive && 'overflow-auto'}`}>
          {sideMenuBar.map(item => (
            <Permission onlyAdmin={item.onlyAdmin} moduleName={item.moduleName} action={item.action} key={item.moduleName}>
              <NavLink to={item.path} activeClassName="active" onClick={item.title === 'Patient' && handleActivePatientTour}>
                <div className={`py-3 mb-5 ${item.title === 'Patient' && 'patient-step-1'}`}>
                  <i className={`la ${item.icon} menu-icon`} />
                  {(item.badge) && <span className="circle-10 bg-danger rounded-circle position-absolute ml-1 mb-9 "></span>}
                  <h6 className="menu-title pt-1">{item.title}</h6>
                </div>
              </NavLink>
            </Permission>
          ))}
        </div>
        <PreferencesModal />
        <BackgroundSelector />
      </div>
      <div className="support-icon">
        <Support />
        {localStorage.getItem("User-Type") !== "non_admin" && <NewFeaturePopup />}
      </div>
    </>
  );
};

export default Sidebar;

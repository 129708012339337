import React, { Component } from 'react';
import { Field, change } from 'redux-form';
import * as $ from 'jquery';

import { Input, Textarea, InputWithIcon, Select, AutoCompleteSelect, AutoCompleteList } from '../../../../../itrust_common/components/form';
import DiscountField from '../DiscountField';
import { handleContactLensFieldsCopy, humanize, isPresentInArray } from '../../../../../itrust_common/components/HelperFunctions';
import { contactLensTypeFields } from '../../../../../itrust_common/components/Constants';
import { connect } from 'react-redux';

const MONTHS = [{ value: 0, label: 0}, { value: 3, label: 3, }, { value: 6, label: 6, }, { value: 9, label: 9}, { value: 12, label: 12}]
class LensForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOdLensTypeLoading: false,
      isOsLensTypeLoading: false,
      osReset: false,
      odReset: false,
      odLensTypeFields: [],
      osLensTypeFields: [],
    }
  }

  copyTo(source, destination) {
    const { member, pprops, currentContactLens } = this.props

    const lensTypeNode = this.refs[`${member}[${source}_contact_lens_id]`].value
    const sourceContactLensType = currentContactLens[`${source}_contact_lens_attributes`]['lens_type']
    const boxNode = this.refs[`${member}[${source}_boxes]`].value
    const unitPriceNode = this.refs[`${member}[${source}_unit_price]`].value
    const copayNode = this.refs[`${member}[${source}_copay]`].value
    const monthsNode = this.refs[`${member}[${source}_months]`].value
    const subTotalNode = this.refs[`${member}[${source}_subtotal]`].value
    const discountNode = currentContactLens[`${source}_discount_type`] === "cash" ?
      this.refs[`${source}DiscountField`]['refs'][`${member}[${source}_cash_discount]`].value :
      this.refs[`${source}DiscountField`]['refs'][`${member}[${source}_percentage_discount]`].value

    pprops.dispatch(change('orderContactLensForm', `${member}[${destination}_contact_lens_attributes]`, currentContactLens[source + '_contact_lens_attributes']))
    pprops.dispatch(change('orderContactLensForm', `${member}[${destination}_contact_lens_id]`, lensTypeNode))

    pprops.dispatch(change('orderContactLensForm', `${member}[${destination}_boxes]`, boxNode))
    pprops.dispatch(change('orderContactLensForm', `${member}[${destination}_unit_price]`, unitPriceNode))
    pprops.dispatch(change('orderContactLensForm', `${member}[${destination}_copay]`, copayNode))
    pprops.dispatch(change('orderContactLensForm', `${member}[${destination}_months]`, monthsNode))
    pprops.dispatch(change('orderContactLensForm', `${member}[${destination}_subtotal]`, subTotalNode))
    pprops.dispatch(change('orderContactLensForm', `${member}[${destination}_discount_type]`, currentContactLens[`${source}_discount_type`]))
    currentContactLens[`${source}_discount_type`] === "cash" ?
      pprops.dispatch(change('orderContactLensForm', `${member}[${destination}_cash_discount]`, discountNode)) :
      pprops.dispatch(change('orderContactLensForm', `${member}[${destination}_percentage_discount]`, discountNode))

    handleContactLensFieldsCopy(this, pprops, sourceContactLensType, member, destination, source, 'orderContactLensForm')
    setTimeout(() => pprops.submit(), 1000)
    setTimeout(() => this.handleContactLensFieldUpdate(destination), 1500);
  }

  renderOdLabel(manufacturer_name) {
    return (
      <span>OD {manufacturer_name && `(${manufacturer_name})`}<small className="float-right btn-link" onClick={() => !this.props.isTemplate && this.copyTo('od', 'os')}><i className="la la-sm la-copy" />OS</small></span>
    )
  }

  renderOsLabel(manufacturer_name) {
    return (
      <span>OS {manufacturer_name && `(${manufacturer_name})`}<small className="float-right btn-link" onClick={() => !this.props.isTemplate && this.copyTo('os', 'od')}><i className="la la-sm la-copy" />OD</small></span>
    )
  }

  buildLensTypeData(arr){
    var data = []
    arr && arr.map((val)=>{
      data=[...data, { key: val, value: val }]
    })
    return data
  }

  onContactLensSelect(type){
    const { member, pprops } = this.props

    this.setState({ [`is${humanize(type)}LensTypeLoading`]: true, [`${type}Reset`]: true})
    pprops.dispatch(change('orderContactLensForm', `${member}[${type}_copay]`, ""));
    pprops.dispatch(change('orderContactLensForm', `${member}[${type}_discount_type]`, "cash"))
    pprops.dispatch(change('orderContactLensForm', `${member}[${type}_cash_discount]`, ""));
    pprops.dispatch(change('orderContactLensForm', `${member}[${type}_percentage_discount]`, ""));
    pprops.dispatch(change('orderContactLensForm', `${member}[${type}_months]`, 0))
    setTimeout(() => pprops.submit(), 500)
    setTimeout(() => this.handleContactLensFieldUpdate(type), 1500);
  }

  handleContactLensFieldUpdate(type) {
    const { currentContactLens: { os_contact_lens_attributes, od_contact_lens_attributes } } = this.props
    const lensType = type === 'od' ? od_contact_lens_attributes.lens_type : os_contact_lens_attributes.lens_type
    this.setState({ [`${type}LensTypeFields`]: contactLensTypeFields[lensType]})
  }

  getMonths(modality){
    var MONTHS = []
    switch(modality) {
      case 3: MONTHS = this.buildLensTypeData([0,3,6,9,12])
        return MONTHS

      case 6: MONTHS = this.buildLensTypeData([0,6,12])
        return MONTHS

      case 12: MONTHS = this.buildLensTypeData([0,12])
        return MONTHS

      default: MONTHS = this.buildLensTypeData([0,1,2,3,4,5,6,7,8,9,10,11,12])
        return MONTHS
    }

  }

  getBoxesCount(modality) {
    var boxes = []
    switch (modality) {
      case 3: boxes = this.buildLensTypeData([0, 1, 2, 3, 4])
        return boxes

      case 6: boxes = this.buildLensTypeData([0, 1, 2])
        return boxes

      case 12: boxes = this.buildLensTypeData([0, 1])
        return boxes

      default: boxes = this.buildLensTypeData([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
        return boxes
    }

  }

  handleAddSearchValSubmit(val, contact_lens_id, field_name, type) {
    const { member, pprops } = this.props
    const addToSettings = { type: type, val: val, field_name: field_name, setting_contact_lens_id: contact_lens_id }
    this.props.dispatch(change('orderContactLensForm', `${member}[add_lens_values]`, addToSettings))
    setTimeout(() => pprops.submit(), 500)
  }

  componentDidMount(){
    const { currentContactLens: { os_contact_lens_attributes, od_contact_lens_attributes } } = this.props
    this.setState({
      odReset: false, osReset: false,
      odLensTypeFields: contactLensTypeFields[od_contact_lens_attributes?.lens_type],
      osLensTypeFields: contactLensTypeFields[os_contact_lens_attributes?.lens_type],
    })
  }

  getStoreIdForSearch() {
    const { orderStoreId } = this.props
    let StoreId = localStorage.getItem('StoreID')
    if (!StoreId && StoreId == null) {
      return orderStoreId
    }
    return StoreId;
  }

  render() {
    const { member, pprops, currentContactLens, currentContactLens: {od_contact_lens_attributes, os_contact_lens_attributes} } = this.props
    return (
      <div className="col-12 p-6">
        <div className="row mb-3">
          <div className="col-12">
            <Field
              key={od_contact_lens_attributes && od_contact_lens_attributes.id}
              ref={`${member}[od_contact_lens_id]`}
              name={`${member}[od_contact_lens_id]`}
              displayName={`${member}[od_contact_lens_attributes][brand_name]`}
              component={AutoCompleteSelect}
              onChangeHook={() => this.onContactLensSelect("od") }
              klass="Settings::ContactLens"
              additionalFilters={{ store_inventory: this.getStoreIdForSearch() }}
              textField="brand_name"
              valueField="id"
              placeholder="Placeholder"
              wrapperClasses="col-4 px-0 form-group"
              className="form-control"
              label={this.renderOdLabel(od_contact_lens_attributes?.manufacturer_name)}
              labelClasses="w-100" />
          </div>

          <div className="col-5">
            {this.state.isOdLensTypeLoading ?
              this.loadingSpinner("isOdLensTypeLoading") :
              <div className="row">
                {this.state.odLensTypeFields?.map((fieldName) => {
                  return isPresentInArray(this.state.odLensTypeFields, `${fieldName}`) && this.renderLensFields('od', fieldName, member, od_contact_lens_attributes)
                })}
              </div>
            }
          </div>

          <Field
            label="Unit Price"
            ref={`${member}[od_unit_price]`}
            name={`${member}[od_unit_price]`}
            component={InputWithIcon}
            title="$"
            labelClasses="w-100"
            className="form-control disabled bg-light"
            wrapperClasses="col-1"
          />

          <Field
            label="Copay"
            ref={`${member}[od_copay]`}
            name={`${member}[od_copay]`}
            component={InputWithIcon}
            title="$"
            labelClasses="w-100"
            className="form-control"
            wrapperClasses="col-1"
          />

          <Field
            label="Boxes"
            ref={`${member}[od_boxes]`}
            name={`${member}[od_boxes]`}
            component={Select}
            onChange={() => setTimeout(() => pprops.submit(), 500)}
            reset={this.state.odReset}
            data={this.getBoxesCount(od_contact_lens_attributes?.modality)}
            textField="key"
            valueField="value"
            wrapperClasses="col-1"
          />

          <Field
            key={od_contact_lens_attributes && od_contact_lens_attributes.id}
            label="Months"
            ref={`${member}[od_months]`}
            name={`${member}[od_months]`}
            component={Select}
            onChange={()=> setTimeout(() => pprops.submit(), 500)}
            reset={this.state.odReset}
            data={this.getMonths(od_contact_lens_attributes && od_contact_lens_attributes.modality)}
            textField="key"
            valueField="value"
            wrapperClasses="col-1"
          />
          <div className="col-2">
            <label>Discount</label>
            <DiscountField
              key={currentContactLens.od_discount_type}
              ref={"odDiscountField"}
              cashName={`${member}[od_cash_discount]`}
              percentageName={`${member}[od_percentage_discount]`}
              discountTypeName={`${member}[od_discount_type]`}
              defaultType={currentContactLens.od_discount_type}
              prependClasses="py-2"
            />
          </div>
          <Field
            label="Item Subtotal"
            ref={`${member}[od_subtotal]`}
            name={`${member}[od_subtotal]`}
            component={InputWithIcon}
            shouldRoundDecimalVal={2}
            title="$"
            labelClasses="w-100"
            className="form-control disabled bg-light"
            wrapperClasses="col-1"
          />

        </div>
        <div className="row mb-3">
          <div className="col-12">
            <Field
              key={os_contact_lens_attributes && os_contact_lens_attributes.id}
              ref={`${member}[os_contact_lens_id]`}
              name={`${member}[os_contact_lens_id]`}
              displayName={`${member}[os_contact_lens_attributes][brand_name]`}
              component={AutoCompleteSelect}
              onChangeHook={() => this.onContactLensSelect("os")}
              klass="Settings::ContactLens"
              additionalFilters={{ store_inventory: this.getStoreIdForSearch() }}
              textField="brand_name"
              valueField="id"
              placeholder="Placeholder"
              wrapperClasses="col-4 px-0 form-group"
              className="form-control"
              label={this.renderOsLabel(os_contact_lens_attributes?.manufacturer_name)}
              labelClasses="w-100" />
          </div>

          <div className="col-5">
            {this.state.isOsLensTypeLoading ?
              this.loadingSpinner("isOsLensTypeLoading") :
              <div className="row">
                {this.state.osLensTypeFields?.map((fieldName) => {
                  return isPresentInArray(this.state.osLensTypeFields, `${fieldName}`) && this.renderLensFields('os', fieldName, member, os_contact_lens_attributes)
                })}
              </div>
            }
          </div>

          <Field
            label="Unit Price"
            ref={`${member}[os_unit_price]`}
            name={`${member}[os_unit_price]`}
            component={InputWithIcon}
            title="$"
            labelClasses="w-100"
            className="form-control disabled bg-light"
            wrapperClasses="col-1"
          />

          <Field
            label="Copay"
            ref={`${member}[os_copay]`}
            name={`${member}[os_copay]`}
            component={InputWithIcon}
            title="$"
            labelClasses="w-100"
            className="form-control"
            wrapperClasses="col-1"
          />

          <Field
            label="Boxes"
            ref={`${member}[os_boxes]`}
            name={`${member}[os_boxes]`}
            component={Select}
            onChange={() => setTimeout(() => pprops.submit(), 500)}
            reset={this.state.odReset}
            data={this.getBoxesCount(os_contact_lens_attributes?.modality)}
            textField="key"
            valueField="value"
            wrapperClasses="col-1"
          />

          <Field
            key={os_contact_lens_attributes && os_contact_lens_attributes.id}
            label="Months"
            ref={`${member}[os_months]`}
            name={`${member}[os_months]`}
            component={Select}
            reset={this.state.osReset}
            data={this.getMonths(os_contact_lens_attributes && os_contact_lens_attributes.modality)}
            onChange={()=> setTimeout(() => pprops.submit(), 500)}
            textField="key"
            valueField="value"
            wrapperClasses="col-1"
          />

          <div className="col-2">
            <label>Discount</label>
            <DiscountField
              key={currentContactLens.os_discount_type}
              ref={"osDiscountField"}
              cashName={`${member}[os_cash_discount]`}
              percentageName={`${member}[os_percentage_discount]`}
              discountTypeName={`${member}[os_discount_type]`}
              defaultType={currentContactLens.os_discount_type}
              prependClasses="py-2"
            />
          </div>


          <Field
            label="Item Subtotal"
            ref={`${member}[os_subtotal]`}
            name={`${member}[os_subtotal]`}
            component={InputWithIcon}
            shouldRoundDecimalVal={2}
            title="$"
            labelClasses="w-100"
            className="form-control disabled bg-light"
            wrapperClasses="col-1"
          />

        </div>
        <div className="row">
          <div className="col-12 mb-6">
            <h6>Benefits</h6>
          </div>
          <div className="col-6">
            <div className="row">
              <Field
                label="Material Copay"
                name={`${member}[material_copay]`}
                component={InputWithIcon}
                title="$"
                labelClasses="w-100"
                className="form-control"
                wrapperClasses="col-4 mb-5"
              />

              <Field
                label="Allowance"
                name={`${member}[allowance]`}
                component={InputWithIcon}
                onBlur={()=> setTimeout(() => pprops.submit(), 500)}
                title="$"
                labelClasses="w-100"
                className="form-control"
                wrapperClasses="col-4 mb-5"
              />

              <Field
                label="Contact Lens Fit"
                name={`${member}[shared_allowance_cl_fit]`}
                component={InputWithIcon}
                title="$"
                labelClasses="w-100"
                className="form-control"
                wrapperClasses="col-4 mb-5"
              />

              <div className="col-4 mb-5">
              </div>

              <div className="col-4 mb-5">
                <label>Overall Discount</label>
                <DiscountField
                  cashName={`${member}[overall_cash_discount]`}
                  percentageName={`${member}[overall_percentage_discount]`}
                  discountTypeName={`${member}[overall_discount_type]`}
                  defaultType={currentContactLens.overall_discount_type}
                  prependClasses="py-2"
                />
              </div>

              <Field
                label={<span>Remaining<small className="float-right btn-link pt-2"><i className="la la-sm la-info-circle" type="button" id={`cl-remaining-${currentContactLens.id}`} data-toggle="tooltip" onMouseEnter={()=> $(`#cl-remaining-${currentContactLens.id}`).tooltip('show')} onMouseLeave={()=> $(`#cl-remaining-${currentContactLens.id}`).tooltip('hide')} data-placement="top" title="Remaining_allowance = allowance- contact lens fit" /></small></span>}
                name={`${member}[remaining_allowance]`}
                component={InputWithIcon}
                title="$"
                labelClasses="w-100"
                className="form-control disabled"
                wrapperClasses="col-4 mb-5"
              />
            </div>
          </div>

          <Field
            label="Comment"
            name={`${member}[notes]`}
            component={Textarea}
            rows="5"
            className="form-control"
            wrapperClasses="col-4 form-group px-7"
          />

          <Field
            label={<span>Total<small className="float-right btn-link pt-2"><i className="la la-sm la-info-circle" type="button" id={`cl-total-${currentContactLens.id}`} data-toggle="tooltip" onMouseEnter={()=> $(`#cl-total-${currentContactLens.id}`).tooltip('show')} onMouseLeave={()=> $(`#cl-total-${currentContactLens.id}`).tooltip('hide')} data-placement="top" title="Total = od subtotal + os subtotal + material_copay -  overall_cash_discount - remaining allowance
            " /></small></span>}
            name={`${member}[total]`}
            component={InputWithIcon}
            shouldRoundDecimalVal={2}
            title="$"
            labelClasses="w-100"
            className="form-control disabled"
            wrapperClasses="col-2 mb-5"
          />
        </div>
      </div>
    );
  }

  renderLensFields(lens_type, fieldName, member, contact_lens_attributes) {
    const contactLensFields = { base_curves: 'curve', diameters: 'diameter', spheres: 'sphere' };
    const contactLensOrderField = contactLensFields[fieldName] || fieldName;
    return (
      <>
        <Field
          ref={`${member}[${lens_type}_${contactLensOrderField}]`}
          name={`${member}[${lens_type}_${contactLensOrderField}]`}
          label={`${humanize(contactLensOrderField)}`}
          component={AutoCompleteSelect}
          reset={lens_type === 'od' ? this.state.odReset : this.state.osReset}
          data={this.buildLensTypeData(contact_lens_attributes && contact_lens_attributes[fieldName])}
          textField="value"
          valueField="key"
          wrapperClasses="col-3 mb-4"
          key={`${member}[${lens_type}_${contactLensOrderField}]-${contact_lens_attributes?.id}`}
          displayName={`${member}[${lens_type}_${contactLensOrderField}]`}
          hideResetButton={true}
          className="form-control"
          labelClasses="w-100"
          showAddOption={true}
          addSearchValSubmit={(val) => this.handleAddSearchValSubmit(val, contact_lens_attributes?.id, contactLensOrderField, lens_type)}
        />
      </>
    )
  }

  loadingSpinner(fieldType){
    return(
      <div className="col-6 d-flex align-items-center">
        <span className="spinner-border text-primary" style={{width: '1.25rem', height: '1.25rem'}} role="status" />
        <h6 className="d-inline-block pl-3">Loading Lens Type...</h6>
        <span className="d-none">{setTimeout(()=>{this.setState({[fieldType]: false})},3000)}</span>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { order: { order } } = state
  return {
    orderStoreId: order.store_id
  }
}

export default connect(mapStateToProps)(LensForm);

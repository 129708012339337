import React from 'react'

export default function Tooltip(props) {
  const { children, tooltipContent } = props

  return (
    <>
      {tooltipContent ?
        <div className='dropdownHover'>
          <div class="dropup">
            <span>{children}</span>
            <div class="dropdown-menu p-3 max-height-9 font-weight-500 font-size-14 line-height-normal overflow-auto">{tooltipContent}</div>
          </div>
        </div> :
        <div>{children}</div>
      }
    </>
  )
}
import Toastr from "../../../itrust_common/components/Toastr"
const parent = 'ORGANISATION_'

const DEFAULT_STATE = {
  payment_methods: [],
  previous_payments: [],
  payment_method: {},
  editing: false,
  saving: false
}
let changes = null;
export default function paymentMethodReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let paymentMethods = [];
  let paymentMethodData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}PAYMENT_METHODS_LOAD_PENDING`:
    case `${parent}PAYMENT_METHOD_LOAD_PENDING`:
    case `${parent}PREVIOUS_PAYMENTS_LOAD_PENDING`:
      return {...state, saving: true};

    case `${parent}PAYMENT_METHOD_CREATE_PENDING`:
    case `${parent}PAYMENT_METHOD_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}PAYMENT_METHODS_LOAD_REJECTED`:
    case `${parent}PAYMENT_METHOD_LOAD_REJECTED`:
    case `${parent}PAYMENT_METHOD_CREATE_REJECTED`:
    case `${parent}PAYMENT_METHOD_UPDATE_REJECTED`:
    case `${parent}PREVIOUS_PAYMENTS_LOAD_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}PAYMENT_METHODS_LOAD_FULFILLED`:
      changes = {
        payment_methods: response.payment_methods.data,
        saving: false
      };
      return {...state, ...changes};

    case `${parent}PREVIOUS_PAYMENTS_LOAD_FULFILLED`:
      changes = {
        previous_payments: response.invoices.data,
        saving: false
      };
      return { ...state, ...changes };

    
    case `${parent}PAYMENT_METHOD_LOAD_FULFILLED`:
      changes = {
        payment_method: response.payment_method,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}PAYMENT_METHOD_CREATE_FULFILLED`:
    case `${parent}PAYMENT_METHOD_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      paymentMethods = [...state.payment_methods]
      let newRecord = response.payment_method
      changes = {
        payment_methods: [response.payment_method],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}PAYMENT_METHOD_EDIT`:
      changes = {
        editing: action.id ? { id: action.id } : false,
        payment_method: {}
      };
      return { ...state, ...changes };

    case `${parent}PAYMENT_METHOD_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const useOnboardingTourData = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.auth.user?.id);
  const onBoarding = useSelector(state => state.onBoarding);
  const onboardingGuide = useSelector(state => state.onBoarding.onBoardingGuide);
  const appLayout = useSelector(state => state.other.layout);
  const userRole = useSelector(state => state.auth.user?.role);
  const staff_stores = useSelector(state => state.staffStore.staff_stores);
  const userOnboardingGuide = useSelector(state => state.auth.user?.onboarding_guide);
  const orgOnboardingDetails = useSelector(state => state.organisation.organisation?.onboarding_details);
  const organisationStatus = useSelector(state => state.organisation.organisation?.status);
  return { dispatch, onBoarding, history, userId, userRole, staff_stores, appLayout, onboardingGuide, userOnboardingGuide, orgOnboardingDetails, organisationStatus }
}
